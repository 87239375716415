import { useCallback, useState } from 'react'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useEvaluations } from 'app/core/hooks/evaluation'
import { EvaluationFilterProps } from 'app/core/types/evaluation'

import { EvaluationHistoryTemplate } from './template'

const EvaluationHistory: React.FC = () => {
  const [filters, setFilters] = useState<EvaluationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { evaluations, isLoading, exportEvaluations, isLoadingExport } =
    useEvaluations({
      filters,
      page,
    })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterEvaluation = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      setPage(1)
      setFilters(filters as EvaluationFilterProps)

      handleToggleDrawer()
    },
    []
  )

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: exportEvaluations,
    },
  ]

  return (
    <EvaluationHistoryTemplate
      evaluations={evaluations}
      currentPage={page}
      setCurrentPage={setPage}
      onExportClick={exportEvaluations}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmitFilter={handleFilterEvaluation}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { EvaluationHistory }
