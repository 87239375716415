import React, { Dispatch, SetStateAction } from 'react'

import { ScheduleExpandedInfo } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { FormType, IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { ScheduleReadResponseData } from 'app/core/types/hormonal'

import styles from './styles.module.scss'

type ScheduleHistoryProps = {
  schedules?: ScheduleReadResponseData
  farms?: SelectItemProps[]
  sectors?: SelectItemProps[]
  breedingStations?: SelectItemProps[]
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
  isModalOpen: boolean
  handleToggleModal: (scheduleId?: number) => void
  onDeleteClick: () => Promise<void>
  onCreateClick: () => void
  onScheduleClick: (id: number) => void
  form: FormType
  activeFilters: number
  onResetFilterClick: () => void
  onExportClick: () => void
  isLoadingExport: boolean
}

const ScheduleHistoryTemplate: React.FC<ScheduleHistoryProps> = ({
  schedules,
  farms,
  sectors,
  breedingStations,
  currentPage,
  setCurrentPage,
  isDrawerOpen,
  handleToggleDrawer,
  isLoading,
  isModalOpen,
  handleToggleModal,
  onDeleteClick,
  onCreateClick,
  onScheduleClick,
  activeFilters,
  form: Form,
  onResetFilterClick,
  onExportClick,
  isLoadingExport,
}) => {
  return (
    <>
      <BoxedLayout
        title="Programação IATF"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={onCreateClick}
        currentPage={currentPage}
        totalPages={schedules?.pages}
        setCurrentPage={setCurrentPage}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
      >
        {withLoading(
          <>
            <DataCounter
              counter={schedules?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.schedulesContainer}>
              {schedules?.items.map((schedule, i) => (
                <ExpandCard
                  id={schedule.id.toString()}
                  title={schedule.name}
                  initDate={schedule.date_start}
                  endDate={schedule.date_end}
                  navigation={(): void => onScheduleClick(schedule.id)}
                  key={`schedule-${i}`}
                  buttonLabel="Editar"
                  buttonIcon={IconNames.pencil}
                  animalsFemale={schedule.total_females}
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      handleToggleModal(schedule.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                >
                  <ScheduleExpandedInfo
                    stationName={schedule.breeding_station_name}
                    protocolName={schedule.hormonal_protocol_name}
                  />
                </ExpandCard>
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={onResetFilterClick}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            {farms && (
              <DrawerCol>
                <Form.Select label="Fazenda" name="farm_id" options={farms} />
              </DrawerCol>
            )}
            {sectors && (
              <DrawerCol>
                <Form.Select label="Setor" name="sector_id" options={sectors} />
              </DrawerCol>
            )}
          </DrawerRow>

          <DrawerRow>
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <Form.InputText name="init_date" htmlType="date" />
                <Form.InputText name="final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {breedingStations && (
              <DrawerCol>
                <Form.Select
                  label="Estação de monta"
                  name="breeding_station_id"
                  options={breedingStations}
                />
              </DrawerCol>
            )}
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="ID da Programação"
                name="schedule_id"
                placeholder="Pesquisar ID da programação"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nome da Programação"
                name="schedule_name"
                placeholder="Pesquisar nome da programação"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              label="Aplicar"
              type={ButtonType.primary}
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={isModalOpen}
        handleCloseModal={(): void => handleToggleModal(undefined)}
        modalHeader={`Excluir Programação IATF`}
        modalText={`Você tem certeza que deseja excluir esta programação IATF?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={onDeleteClick}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={(): void => handleToggleModal(undefined)}
      />
    </>
  )
}

export { ScheduleHistoryTemplate }
