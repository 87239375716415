import React, { useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  QuantityBadge,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CHIP_SEX } from 'components/templates/animal-layout/consts'

import { AnimalEvaluations, EvaluationProps } from 'app/core/types/evaluation'
import { FarmProps } from 'app/core/types/system'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'

export interface IExpandCardProps {
  id?: string
  expandable?: boolean
  title?: string
  animals?: number
  animalsMale?: number
  animalsFemale?: number
  animalsProportion?: boolean
  birthMothersStockNumber?: string
  birthElectronicEarTag?: string
  birthCalfsName?: string
  birthNumber?: number
  birthDate?: string
  animalsProportionHint?: string
  singleDate?: string
  dateLabel?: string
  initDate?: string
  endDate?: string
  breedingStation?: string
  isActive?: boolean
  entryType?: string
  navigation?: () => void
  buttonLabel?: string
  buttonIcon?: IconNames
  buttonIconPosition?: ButtonIconPosition
  buttonSize?: ButtonSize
  buttonAction?: () => void
  secondaryButtonLabel?: string
  secondaryButtonIcon?: IconNames
  secondaryButtonIconPosition?: ButtonIconPosition
  secondaryButtonSize?: ButtonSize
  secondaryButtonAction?: () => void
  hideSecondaryButtonLabel?: boolean
  gap?: string
  disableColEqualSize?: boolean
  className?: string
  evaluation?: EvaluationProps
  farm?: FarmProps
  onMotherStockNumberClick?: () => void
  onCalfNameClick?: () => void
}

type ExpandCardProps = IExpandCardProps

const ExpandCard: React.FC<ExpandCardProps> = ({
  id,
  className,
  expandable = true,
  title,
  animals,
  animalsMale,
  animalsFemale,
  animalsProportion,
  animalsProportionHint,
  birthMothersStockNumber,
  birthElectronicEarTag,
  birthCalfsName,
  birthNumber,
  birthDate,
  evaluation,
  singleDate,
  dateLabel,
  breedingStation,
  initDate,
  endDate,
  entryType,
  isActive,
  navigation,
  buttonLabel,
  buttonIcon,
  buttonIconPosition = ButtonIconPosition.left,
  buttonSize = ButtonSize.medium,
  buttonAction,
  secondaryButtonLabel,
  secondaryButtonIcon,
  secondaryButtonIconPosition = ButtonIconPosition.left,
  secondaryButtonSize = ButtonSize.medium,
  secondaryButtonAction,
  children,
  gap,
  disableColEqualSize,
  farm,
  onMotherStockNumberClick,
  onCalfNameClick,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const actionProps = {
    role: 'button',
    onClick: navigation,
  }

  const toggleExpand = (): void => {
    if (expandable) setOpen(!open)
  }

  return (
    <div className={classNames(styles.expandCard, className)}>
      <div className={styles.expandCardHeader} onClick={toggleExpand}>
        <div
          {...actionProps}
          className={classNames(
            styles.expandCardInfo,
            disableColEqualSize ? styles.disableColEqualSize : '',
            className
          )}
          style={gap ? { gap } : {}}
        >
          {id && (
            <span className={styles.expandCardId}>
              <Typography text={id} variant={TypographyVariant.caption2} />
            </span>
          )}

          {!!title && (
            <Typography
              text={title}
              variant={TypographyVariant.h5}
              className={styles.expandCardType}
              title={title}
            />
          )}

          {!!entryType && (
            <span className={styles.expandCardInfoColumn}>
              <span className={styles.caption3}>Tipo de Lançamento</span>
              <Typography text={entryType} variant={TypographyVariant.h6} />
            </span>
          )}

          {animals !== undefined && animals !== null && (
            <QuantityBadge count={animals} label="Animal" />
          )}

          {birthMothersStockNumber !== undefined && (
            <span
              className={styles.expandCardInfoColumn}
              onClick={(): void => onMotherStockNumberClick?.call(this)}
            >
              <span className={styles.caption3}>Nº de plantel</span>
              <Typography
                text={!birthMothersStockNumber ? '-' : birthMothersStockNumber}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {birthElectronicEarTag !== undefined && (
            <span className={styles.expandCardInfoColumn}>
              <span className={styles.caption3}>Nº de Brinco Eletrônico</span>
              <Typography
                text={!birthElectronicEarTag ? '-' : birthElectronicEarTag}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {birthCalfsName !== undefined && (
            <span
              className={styles.expandCardInfoColumn}
              onClick={(): void => onCalfNameClick?.call(this)}
            >
              <span className={styles.caption3}>Nome da cria</span>
              <Typography
                text={!birthCalfsName ? '-' : birthCalfsName}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {birthNumber !== undefined && (
            <span className={styles.expandCardInfoColumn}>
              <span className={styles.caption3}>Nº de Nascimento</span>
              <Typography
                text={!birthNumber ? '-' : birthNumber}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {birthDate && (
            <span className={styles.expandCardInfoColumn}>
              <span className={styles.caption3}>Data de nascimento</span>
              <Typography
                text={dateTimeFormat(birthDate)}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {evaluation && (
            <>
              <span className={styles.expandCardInfoColumn}>
                <span className={styles.caption3}>Data</span>
                <Typography
                  text={dateTimeFormat(evaluation.date)}
                  variant={TypographyVariant.h6}
                />
              </span>

              <span className={styles.expandCardInfoColumn}>
                <span className={styles.caption3}>Nota</span>
                <Typography
                  text={AnimalEvaluations[evaluation.score]}
                  variant={TypographyVariant.h6}
                />
              </span>

              <span
                className={classNames(
                  styles.expandCardInfoColumn,
                  styles.expandCardInfoColumnEvaluation
                )}
              >
                <span className={styles.caption3}>Avaliação</span>
                <Typography
                  text={evaluation.evaluation ?? '-'}
                  variant={TypographyVariant.h6}
                />
              </span>

              <span className={styles.expandCardInfoColumn}>
                <span className={styles.caption3}>Nº de Plantel</span>
                <Typography
                  text={evaluation.stock_number ?? '-'}
                  variant={TypographyVariant.h6}
                />
              </span>

              <span className={styles.expandCardInfoColumn}>
                <span className={styles.caption3}>Nº de Nascimento</span>
                <Typography
                  text={evaluation.birth_number ?? '-'}
                  variant={TypographyVariant.h6}
                />
              </span>

              {evaluation.birth_date && (
                <span className={styles.expandCardInfoColumn}>
                  <span className={styles.caption3}>Data de Nascimento</span>
                  <Typography
                    text={dateTimeFormat(evaluation?.birth_date)}
                    variant={TypographyVariant.h6}
                  />
                </span>
              )}

              <span className={styles.expandCardInfoColumn}>
                <span className={styles.caption3}>Sexo</span>
                <Chip colorVariant={CHIP_SEX[evaluation.sex]} />
              </span>
            </>
          )}

          {farm && (
            <div className={styles.expandCardFarmInfo}>
              <span className={styles.farmName}>
                <span className={styles['caption3--alt']}>FAZENDA</span>
                <Typography text={farm.name} variant={TypographyVariant.h5} />
              </span>

              <div>
                <QuantityBadge
                  id={`farmActiveAnimals--${farm.id}`}
                  count={farm.active_animals}
                  label="Animais Ativos"
                />
              </div>

              <div>
                <QuantityBadge
                  id={`farmActiveSectors--${farm.id}`}
                  count={farm.active_sectors}
                  label="Setores Ativos"
                />
              </div>

              <Chip
                colorVariant={
                  farm.status
                    ? ChipColorVariant.statusActive
                    : ChipColorVariant.statusInactive
                }
              />
            </div>
          )}

          {animalsFemale !== undefined && animalsFemale !== null && (
            <div className={styles.expandCardAnimalBySex}>
              <QuantityBadge count={animalsFemale} label="Fêmea" />
            </div>
          )}

          {animalsMale !== undefined && animalsMale !== null && (
            <div className={styles.expandCardAnimalBySex}>
              <QuantityBadge
                count={animalsMale}
                label="Macho"
                hasWarning={animalsProportion === false}
                warning={animalsProportionHint}
              />
            </div>
          )}

          {singleDate && (
            <span className={classNames(styles.expandCardInfoRow)}>
              <span className={styles.caption3}>{dateLabel || 'Data'}</span>
              <Typography
                text={dateTimeFormat(singleDate)}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {initDate && endDate && (
            <span
              className={
                breedingStation
                  ? styles.expandCardInfoColumn
                  : styles.expandCardInfoRow
              }
            >
              <span className={styles.caption3}>{dateLabel || 'Duração'}</span>

              <span className={styles.expandCardDate}>
                <Typography
                  text={dateTimeFormat(initDate)}
                  variant={TypographyVariant.h6}
                />
                <div className={styles.dateSeparator} />
                <Typography
                  text={dateTimeFormat(endDate)}
                  variant={TypographyVariant.h6}
                />
              </span>
            </span>
          )}

          {breedingStation && (
            <span className={styles.expandCardInfoColumn}>
              <span className={styles.caption3}>Estação de Monta</span>
              <Typography
                text={breedingStation}
                variant={TypographyVariant.h6}
              />
            </span>
          )}

          {isActive !== undefined && (
            <Chip
              colorVariant={
                isActive
                  ? ChipColorVariant.statusActive
                  : ChipColorVariant.statusInactive
              }
            />
          )}
        </div>

        {buttonLabel && (
          <div {...actionProps} className={styles.buttonWrapper}>
            {buttonLabel && (
              <Button
                label={buttonLabel}
                size={buttonSize}
                type={ButtonType.ghost}
                iconPosition={buttonIconPosition}
                icon={<Icon name={buttonIcon} />}
                onClick={buttonAction}
              />
            )}
          </div>
        )}

        {secondaryButtonLabel && (
          <div className={styles.buttonWrapper}>
            {secondaryButtonLabel && (
              <Button
                testId="expand-card-secondary-button"
                label={secondaryButtonLabel ?? ''}
                size={secondaryButtonSize}
                type={ButtonType.ghost}
                iconPosition={secondaryButtonIconPosition}
                icon={<Icon name={secondaryButtonIcon} />}
                onClick={secondaryButtonAction}
              />
            )}
          </div>
        )}

        {expandable && (
          <div role="button" className={styles.expandCardExpandButtonContainer}>
            <Icon
              name={open ? IconNames['chevron-up'] : IconNames['chevron-down']}
              className={styles.buttonIcon}
              size={32}
            />
          </div>
        )}
      </div>

      {expandable && open && children && (
        <div className={styles.expandCardContent} {...actionProps}>
          {open && <>{children}</>}
        </div>
      )}
    </div>
  )
}

export { ExpandCard }
