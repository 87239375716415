import React, { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { DiagnosticReadResponseData } from 'app/core/types/breeding'

import styles from './styles.module.scss'

type DiagnosticsHistoryProps = {
  onDiagnosticCreateClick: () => void
  form: FormType
  activeFilters: number
  onFilterReset: () => void
  groups?: DiagnosticReadResponseData
  onGroupClick: (groupId: number) => void
  onDeleteGroupClick: (groupId: number) => void
  selectedGroup: number | undefined
  onDeleteModalConfirm: (id: number) => void
  onDeleteModalCancel: () => void
  sectors: SelectItemProps[] | undefined
  farms: SelectItemProps[] | undefined
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
  onExportClick: () => Promise<void>
  isLoadingExport: boolean
}

const DiagnosticsHistoryTemplate: React.FC<DiagnosticsHistoryProps> = ({
  onDiagnosticCreateClick,
  form: Form,
  activeFilters,
  onFilterReset,
  groups,
  onGroupClick,
  onDeleteGroupClick,
  selectedGroup,
  onDeleteModalConfirm,
  onDeleteModalCancel,
  sectors,
  farms,
  isDrawerOpen,
  handleToggleDrawer,
  currentPage,
  setCurrentPage,
  isLoading,
  onExportClick,
  isLoadingExport,
}) => {
  return (
    <>
      <BoxedLayout
        title="Diagnósticos Reprodutivos de Gestação"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={onDiagnosticCreateClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={groups?.pages}
        currentPageItems={5}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
      >
        {withLoading(
          <>
            <DataCounter
              counter={groups?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.groupsContainer}>
              {groups?.items.map(group => (
                <ExpandCard
                  className={styles.customExpandCard}
                  expandable={false}
                  navigation={(): void => onGroupClick(group.id)}
                  buttonLabel="Editar"
                  buttonIcon={IconNames.pencil}
                  animals={group.diagnostics_count}
                  singleDate={group.date}
                  disableColEqualSize
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      onDeleteGroupClick(group.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                />
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={onFilterReset}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <Form.InputText name="init_date" htmlType="date" />
                <Form.InputText name="final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {sectors && (
              <DrawerCol>
                <Form.Select label="Setor" name="sector_id" options={sectors} />
              </DrawerCol>
            )}
            {farms && (
              <DrawerCol>
                <Form.Select label="Fazenda" name="farm_id" options={farms} />
              </DrawerCol>
            )}
          </DrawerRow>

          <DrawerRow>
            <DrawerCol column>
              <label htmlFor="diagnostic_init_date">
                Período do Diagnóstico
              </label>
              <div>
                <Form.InputText name="diagnostic_init_date" htmlType="date" />
                <Form.InputText name="diagnostic_final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!selectedGroup}
        handleCloseModal={onDeleteModalCancel}
        modalHeader={`Excluir Grupo de Diagnósticos`}
        modalText={`Você tem certeza que deseja excluir este grupo de diagnósticos?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={(): void => {
          onDeleteModalConfirm(selectedGroup as number)
        }}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={onDeleteModalCancel}
      />
    </>
  )
}

export { DiagnosticsHistoryTemplate }
