import { useState } from 'react'

import { addDays, format, startOfDay } from 'date-fns'
import * as yup from 'yup'

import { CustomModal } from 'components/molecules'
import { useForm } from 'components/organisms'

import { dateToday } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ExportServiceOrderProps = {
  handleExportSubmit: (data: Record<string, unknown>) => Promise<void>
  handleModal: () => void
  isOpen: boolean
}

type DateProps = {
  min?: string
  max?: string
}

const ExportServiceOrders: React.FC<ExportServiceOrderProps> = ({
  handleExportSubmit,
  handleModal,
  isOpen,
}) => {
  const minimumDate = new Date('2020-01-01')
  const inputDateFormat = 'yyyy-MM-dd'

  const [initDate, setInitDate] = useState<DateProps>({
    min: format(addDays(minimumDate, 1), inputDateFormat),
    max: dateToday,
  })
  const [endDate, setEndDate] = useState<DateProps>({
    min: undefined,
    max: dateToday,
  })

  const validationSchema = yup.object({
    initDate: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    endDate: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const { Form, handleSubmit } = useForm({
    onSubmit: handleExportSubmit,
    validationSchema,
  })

  const handleChange = (
    field: 'initDate' | 'endDate',
    selectedDate: Date | null
  ): void => {
    if (field === 'initDate' && selectedDate) {
      const selectedStartDate = startOfDay(selectedDate)
      const minDate = format(selectedStartDate, inputDateFormat)

      setEndDate({
        min: minDate,
        max: dateToday,
      })
    }

    if (field === 'endDate' && selectedDate) {
      const selectedEndDate = startOfDay(selectedDate)
      const maxDate = format(selectedEndDate, inputDateFormat)
      const minimumAllowedDate = endDate.min ? new Date(endDate.min) : null

      if (minimumAllowedDate && selectedEndDate < minimumAllowedDate) return

      setInitDate(prevState => ({
        ...prevState,
        max: maxDate,
      }))
    }
  }

  return (
    <CustomModal
      modalIsOpen={!!isOpen}
      handleCloseModal={handleModal}
      modalHeader="Selecionar período"
      primaryButtonLabel="Exportar"
      primaryButtonAction={handleSubmit(handleExportSubmit)}
      secondaryButtonLabel="Cancelar"
      secondaryButtonAction={handleModal}
    >
      <Form>
        <div className={styles.field}>
          <Form.InputText
            htmlType="date"
            name="initDate"
            label="Data de início"
            onChange={(e): void =>
              handleChange('initDate', e.target.valueAsDate)
            }
            min={initDate.min}
            max={initDate.max}
          />
        </div>
        <div className={styles.field}>
          <Form.InputText
            htmlType="date"
            name="endDate"
            label="Data final"
            onChange={(e): void =>
              handleChange('endDate', e.target.valueAsDate)
            }
            min={endDate.min}
            max={endDate.max}
          />
        </div>
      </Form>
    </CustomModal>
  )
}

export { ExportServiceOrders }
