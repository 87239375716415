import { Dispatch, SetStateAction, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { BirthExpandedInfo } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { useFarm, useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import {
  BIRTH_TYPES,
  BirthsFilterProps,
  BirthsResponseData,
} from 'app/core/types/birth'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
})

type BirthHistoryTemplateProps = {
  births?: BirthsResponseData
  currentPage?: number
  exportBirths: () => Promise<void>
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<BirthsFilterProps | undefined>>
  navigateToCreate: () => void
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
  isLoadingExport: boolean
  handleDeleteBirth: (birthId: number) => void
  onMotherStockNumberClick: (motherId: number) => void
  onCalfNameClick: (calfId: number) => void
  onFatherClick: (fatherId: number, fatherType: string) => void
}

const BirthHistoryTemplate: React.FC<BirthHistoryTemplateProps> = ({
  births,
  currentPage,
  exportBirths,
  setCurrentPage,
  setFilters,
  navigateToCreate,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  isLoading,
  isLoadingExport,
  handleDeleteBirth,
  onMotherStockNumberClick,
  onCalfNameClick,
  onFatherClick,
}) => {
  const history = useHistory()

  const { sectorsDroplist } = useSector()
  const { activeFarmsDroplist: farmsDroplist } = useFarm()

  const { Form, formState, reset } = useForm({ onSubmit, validationSchema })

  const navigateToDetails = (birthId: number): void => {
    history.push(`${NavigateList.birthUpdate}${birthId}`)
  }

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    setFilters(undefined)
    reset()
  }

  const [deleteBirthModalIsOpen, setDeleteBirthModalIsOpen] = useState(false)
  const [selectedBirthId, setSelectedBirthId] = useState<number | null>(null)

  const toggleDeleteBirthModal = (): void =>
    setDeleteBirthModalIsOpen(!deleteBirthModalIsOpen)

  const handleDeleteConfirmation = (): void => {
    if (selectedBirthId && isAdmin()) handleDeleteBirth(selectedBirthId)
    setSelectedBirthId(null)
    toggleDeleteBirthModal()
  }

  return (
    <>
      <BoxedLayout
        title="Partos e Crias"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Novo registro"
        headerLargeButtonAction={navigateToCreate}
        headerButtonAction={exportBirths}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames['download']} />}
        headerButtonLoading={isLoadingExport}
        currentPage={currentPage}
        totalPages={births?.pages}
        setCurrentPage={setCurrentPage}
      >
        {withLoading(
          <>
            <DataCounter
              counter={births?.total}
              counterLabel={'Registros'}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div>
              {births?.items.map(birth => (
                <ExpandCard
                  birthMothersStockNumber={birth.mother_stock_number}
                  onMotherStockNumberClick={(): void =>
                    onMotherStockNumberClick(birth.mother_id)
                  }
                  birthElectronicEarTag={birth.mother_electronic_eartag}
                  birthCalfsName={birth.calf_name}
                  onCalfNameClick={(): void => {
                    if (birth.calf_id) {
                      onCalfNameClick(birth.calf_id as number)
                    }
                  }}
                  birthNumber={birth.calf_birth_number}
                  birthDate={birth.birth_date}
                  buttonAction={(): void => navigateToDetails(birth.id)}
                  buttonIcon={IconNames.pencil}
                  buttonLabel="EDITAR"
                  buttonSize={ButtonSize.small}
                  secondaryButtonAction={(): void => {
                    setSelectedBirthId(birth.id)
                    setDeleteBirthModalIsOpen(true)
                  }}
                  secondaryButtonIcon={IconNames.close}
                  secondaryButtonLabel={isAdmin() ? 'Excluir' : undefined}
                  key={`birth-${birth.id}`}
                >
                  <BirthExpandedInfo
                    data={birth}
                    onFatherClick={(): void => {
                      if (birth.father_id) {
                        onFatherClick(
                          birth.father_id as number,
                          birth.father_type as string
                        )
                      }
                    }}
                  />
                </ExpandCard>
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {farmsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Fazenda"
                  name="farm_id"
                  options={farmsDroplist}
                />
              </DrawerCol>
            )}
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.Select
                label="Tipo de parto"
                name="type"
                options={BIRTH_TYPES}
              />
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <Form.InputText name="init_date" htmlType="date" />
                <Form.InputText name="final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={deleteBirthModalIsOpen}
        handleCloseModal={toggleDeleteBirthModal}
        modalHeader={'Deseja realmente excluir o parto?'}
        primaryButtonLabel="Sim"
        primaryButtonAction={handleDeleteConfirmation}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={toggleDeleteBirthModal}
      />
    </>
  )
}

export { BirthHistoryTemplate }
