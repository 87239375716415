import { Dispatch, SetStateAction } from 'react'

import { RecommendationCard } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  withLoading,
} from 'components/molecules'
import { SearchAnimal } from 'components/organisms'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { NavigateList } from 'app/core/routes/routes'
import { MatingRecommendationReadResponseData } from 'app/core/types/mating-recommendation'

type MatingRecommendationHistoryTemplateProps = {
  recommendations?: MatingRecommendationReadResponseData
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onExportClick: () => void
  isLoading: boolean
  isLoadingExport: boolean
  onResetFilters: () => void
  form: FormType
  activeFilters: number
  farms: SelectItemProps[]
  sectors: SelectItemProps[]
}

const MatingRecommendationHistoryTemplate: React.FC<MatingRecommendationHistoryTemplateProps> =
  ({
    recommendations,
    currentPage,
    setCurrentPage,
    isDrawerOpen,
    handleToggleDrawer,
    onExportClick,
    isLoading,
    isLoadingExport,
    onResetFilters,
    form: Form,
    activeFilters,
    farms,
    sectors,
  }) => {
    return (
      <>
        <BoxedLayout
          title="Recomendações de Acasalamento"
          currentPage={currentPage}
          totalPages={recommendations?.pages}
          setCurrentPage={setCurrentPage}
          headerButtonLabel="Exportar"
          headerButtonIcon={<Icon name={IconNames.download} />}
          headerButtonAction={onExportClick}
          headerButtonLoading={isLoadingExport}
        >
          <SearchAnimal
            navigation={NavigateList.matingRecommendationDetails}
            findOnlyFemale
          />

          {withLoading(
            <>
              <DataCounter
                counter={recommendations?.total}
                counterLabel={'Registros'}
                filterButtonAction={handleToggleDrawer}
                filterButtonActiveFilters={activeFilters}
              />

              {recommendations?.items.map(recommendation => (
                <RecommendationCard
                  key={recommendation.id}
                  recommendation={recommendation}
                />
              ))}
            </>
          )(isLoading)}
        </BoxedLayout>

        <CustomDrawer
          title="Filtrar"
          isOpen={isDrawerOpen}
          onClose={handleToggleDrawer}
          reset={onResetFilters}
        >
          <Form>
            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Brinco Eletrônico"
                  name="electronic_eartag"
                  placeholder="Pesquisar nº de brinco eletrônico"
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Nascimento"
                  name="birth_number"
                  placeholder="Pesquisar nº de nascimento"
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Plantel"
                  name="stock_number"
                  placeholder="Inserir número"
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Nome do Animal"
                  name="animal_name"
                  placeholder="Pesquisar nome do animal"
                />
              </DrawerCol>
              <DrawerCol column>
                <label htmlFor="birth_date_init">Período de Nascimento</label>
                <div>
                  <Form.InputText name="birth_date_init" htmlType="date" />
                  <Form.InputText name="birth_date_end" htmlType="date" />
                </div>
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <Form.Select label="Fazenda" name="farm_id" options={farms} />
              </DrawerCol>
              <DrawerCol>
                <Form.Select label="Setor" name="sector_id" options={sectors} />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol column>
                <label htmlFor="init_date">Período da recomendação</label>
                <div>
                  <Form.InputText name="init_date" htmlType="date" />
                  <Form.InputText name="final_date" htmlType="date" />
                </div>
              </DrawerCol>
            </DrawerRow>

            <DrawerButtonContainer>
              <Button
                type={ButtonType.primary}
                label="Aplicar"
                size={ButtonSize.large}
              />
            </DrawerButtonContainer>
          </Form>
        </CustomDrawer>
      </>
    )
  }

export { MatingRecommendationHistoryTemplate }
