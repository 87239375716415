import { Dispatch, SetStateAction, useImperativeHandle } from 'react'
import Skeleton from 'react-loading-skeleton'

import {
  Button,
  ButtonIconPosition,
  ButtonType,
  Divider,
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { ISelectButtonItem, TreeView, useForm } from 'components/organisms'
import SelectButton from 'components/organisms/select-button'

import {
  ActiveImportProps,
  ClassificationProps,
  ImportHistoryResponse,
} from 'app/core/types/system'
import { dateTimeFormatWithTime } from 'app/core/utils'
import { Messages } from 'config/messages'

import { ClassificationFormRef } from '.'
import * as templateStyles from '../../styles.module.scss'
import styles from './styles.module.scss'

type ClassificationTabTemplateProps = {
  farmsDroplist?: SelectItemProps[]
  classifications?: ClassificationProps[]
  handleModalConfirm: (data: Record<string, unknown>) => Promise<void>
  handleShowMoreHistoryItems: () => void
  historyCount: number
  activeImport?: ActiveImportProps
  toggleHistoryModal: () => void
  toggleImportModal: () => void
  isHistoryModalOpen: boolean
  isImportModalOpen: boolean
  isImportLoading: boolean
  isLoading: boolean
  parentRef: React.RefObject<ClassificationFormRef>
  importedFiles: ImportHistoryResponse[]
  onImportFileClick: (name: string) => void
  sheetMenuOptions: ISelectButtonItem[]
  screenSelectedFarmId?: string | null
  setScreenSelectedFarmId: Dispatch<SetStateAction<string | undefined | null>>
}

const ClassificationTabTemplate: React.FC<ClassificationTabTemplateProps> = ({
  farmsDroplist,
  classifications,
  handleModalConfirm,
  handleShowMoreHistoryItems,
  historyCount,
  activeImport,
  toggleHistoryModal,
  toggleImportModal,
  isHistoryModalOpen,
  isImportModalOpen,
  isImportLoading,
  isLoading,
  parentRef,
  importedFiles,
  onImportFileClick,
  sheetMenuOptions,
  screenSelectedFarmId,
  setScreenSelectedFarmId,
}) => {
  const addedHasUpdate = activeImport && activeImport.added > 0
  const changedHasUpdate = activeImport && activeImport.changed > 0
  const deactivatedHasUpdate = activeImport && activeImport.disables > 0
  const hasNewInvestment = activeImport && activeImport.has_investment

  const showFarmsDropdown =
    farmsDroplist &&
    farmsDroplist?.length > 0 &&
    typeof screenSelectedFarmId !== 'undefined'

  useImperativeHandle(parentRef, () => ({
    resetForm(): void {
      reset()
    },
  }))

  const { Form, reset } = useForm({
    onSubmit: handleModalConfirm,
  })

  return (
    <>
      <Typography
        text="Este parâmetro é importado do seu sistema ERP. Para alterá-lo é necessário criar esta alteração no sistema ERP e exportar a planilha .xlsx de Classificações, e então importar o arquivo aqui."
        variant={TypographyVariant.p}
      />

      <header className={templateStyles.default.parametersHeader}>
        {showFarmsDropdown ? (
          <Select
            name="farm"
            defaultValue={farmsDroplist?.find(
              farm => farm.value === screenSelectedFarmId
            )}
            options={farmsDroplist}
            onChange={(value): void =>
              setScreenSelectedFarmId((value as SelectItemProps).value)
            }
            placeholder="Selecione uma fazenda"
          />
        ) : (
          <Skeleton width={230} height={50} />
        )}

        <div className={templateStyles.default.parametersActions}>
          <Button
            testId="historyButton"
            label="Ver histórico"
            onClick={toggleHistoryModal}
            type={ButtonType.ghost}
          />
          <SelectButton
            testId="sheetOptionsButton"
            icon={<Icon name={IconNames['chevron-down']} />}
            iconPosition={ButtonIconPosition.right}
            label="Planilha"
            items={sheetMenuOptions}
            loading={!isImportModalOpen ? isImportLoading : false}
          />
        </div>
      </header>

      {classifications && !isLoading && (
        <TreeView
          containerClassName={templateStyles.default.treeViewContainer}
          defaultExpanded
          items={classifications}
        />
      )}

      <CustomModal
        modalHeader=""
        modalIsOpen={isImportModalOpen}
        handleCloseModal={toggleImportModal}
      >
        <div className={templateStyles.default.importModal}>
          <Typography
            text="Importar parâmetros de classificações"
            variant={TypographyVariant.h2}
          />

          <Typography
            text={`Arquivo: ${activeImport?.fileName}`}
            variant={TypographyVariant.p}
          />

          <Typography
            text="Esta ação irá atualizar os parâmetros atuais. Classificações podem ser adicionadas, alteradas ou removidas."
            variant={TypographyVariant.p}
          />

          <ul className={templateStyles.default.importModalChanges}>
            <li
              {...(addedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Adicionadas: {activeImport?.added}
            </li>
            <li
              {...(changedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Alteradas: {activeImport?.changed}
            </li>
            <li
              {...(deactivatedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Desativadas: {activeImport?.disables}
            </li>
          </ul>

          <Divider darker />

          <div className={styles.importMessage}>
            {hasNewInvestment && (
              <Typography
                text="Algumas novas classificações são referentes a investimentos. Escreva uma mensagem explicando aos usuários pra quais tipos de serviços elas devem ser utilizadas."
                variant={TypographyVariant.p}
              />
            )}

            <Form>
              {hasNewInvestment && (
                <Form.Textarea
                  containerClassName={styles.importMessageTextarea}
                  name="message"
                  label="Quando utilizar estas novas classificações"
                  placeholder="Descreva aqui"
                />
              )}

              <div className={templateStyles.default.importModalActions}>
                <Button
                  testId="applyChangesButton"
                  label="Aplicar alterações"
                  type={ButtonType.primary}
                  loading={isImportLoading}
                />
                <Button
                  htmlType="button"
                  onClick={toggleImportModal}
                  label="Cancelar"
                  type={ButtonType.ghost}
                />
              </div>
            </Form>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        modalHeader=""
        modalIsOpen={isHistoryModalOpen}
        handleCloseModal={toggleHistoryModal}
      >
        <div className={templateStyles.default.importModal}>
          <Typography
            text="Histórico de importações de parâmetros de classificações"
            variant={TypographyVariant.h2}
          />

          <Typography
            text="Histórico das planilhas xlsx de parâmetros de classificações importados do seu sistema ERP"
            variant={TypographyVariant.p}
          />

          {importedFiles.length === 0 ? (
            <Typography
              className={templateStyles.default.emptyState}
              text={Messages.SYSTEM_PARAMETERS_IMPORT_EMPTY_DATA}
              variant={TypographyVariant.p}
            />
          ) : (
            <>
              <ul className={templateStyles.default.importModalHistory}>
                {importedFiles
                  .slice(0, historyCount)
                  .map(({ id, name, date }) => (
                    <li key={id}>
                      <Button
                        testId="downloadFileButton"
                        onClick={(): void => onImportFileClick(name)}
                        label={`Importação ${dateTimeFormatWithTime(date)}`}
                        type={ButtonType.outline}
                        icon={<Icon name={IconNames.download} />}
                      />
                    </li>
                  ))}
              </ul>

              <div className={templateStyles.default.importModalActions}>
                <Button
                  testId="showMoreButton"
                  disabled={historyCount > importedFiles.length}
                  onClick={handleShowMoreHistoryItems}
                  label="Mostrar mais"
                  type={ButtonType.ghost}
                />
              </div>
            </>
          )}
        </div>
      </CustomModal>
    </>
  )
}

export { ClassificationTabTemplate }
