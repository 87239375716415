import { useCallback, useState } from 'react'

import * as yup from 'yup'

import { useForm } from 'components/organisms'

import { useFarm, useSector } from 'app/core/hooks'
import { useMatingRecommendations } from 'app/core/hooks/mating-recommendation'
import { MatingRecommendationFilterProps } from 'app/core/types/mating-recommendation'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

import { MatingRecommendationHistoryTemplate } from './template'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
  birth_date_init: yup.string(),
  birth_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { birth_date_init } = this.parent
        return isEndDateBeforeStartDate(birth_date_init, value)
      }
    ),
})

const MatingRecommendationHistory: React.FC = () => {
  const [filters, setFilters] = useState<MatingRecommendationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const {
    recommendations,
    isLoading,
    exportMatingRecommendations,
    isLoadingExport,
  } = useMatingRecommendations({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterMatingRecommendation = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      setPage(1)
      setFilters(filters as MatingRecommendationFilterProps)

      handleToggleDrawer()
    },
    []
  )

  const { activeFarmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()

  const { Form, formState, reset } = useForm({
    onSubmit: handleFilterMatingRecommendation,
    validationSchema,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const handleResetFilters = (): void => {
    setFilters(undefined)
    reset()
  }

  return (
    <MatingRecommendationHistoryTemplate
      recommendations={recommendations}
      currentPage={page}
      setCurrentPage={setPage}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onExportClick={exportMatingRecommendations}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
      onResetFilters={handleResetFilters}
      form={Form}
      activeFilters={activeFilters}
      sectors={sectorsDroplist ?? []}
      farms={activeFarmsDroplist ?? []}
    />
  )
}

export { MatingRecommendationHistory }
