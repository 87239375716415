import { useCallback, useEffect, useState } from 'react'

import { getReadAllAnimals, getReadAnimalByCalf } from 'app/core/services'
import {
  getReadAllEvaluations,
  postCreateEvaluation,
} from 'app/core/services/evaluation'
import { AnimalFilterProps, AnimalSex } from 'app/core/types/animal'
import {
  AnimalEvaluations,
  EvaluationAnimalSearchType,
  EvaluationCreateRequestData,
  EvaluationFilterProps,
  EvaluationProps,
  EvaluationReadResponseData,
} from 'app/core/types/evaluation'
import { EvaluationHook, EvaluationHookProps } from 'app/core/types/hooks'
import {
  DEFAULT_ITEMS_PER_PAGE_DETAILS,
  EXPORT_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  dateTimeXslxFormat,
  downloadFile,
  generateXlsxTemplate,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useEvaluations = ({
  animalId,
  filters,
  page,
  size,
}: EvaluationHookProps): EvaluationHook => {
  const [evaluations, setEvaluations] = useState<EvaluationReadResponseData>()
  const [animalEvaluations, setAnimalEvaluations] = useState<EvaluationProps[]>(
    []
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllEvaluations = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllEvaluations(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_DETAILS
      )

      if (data.total === 0) {
        addToast({
          message: Messages.EVALUATIONS_NOT_FOUND,
          type: 'warning',
        })
      }

      setEvaluations(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const getAnimalEvaluations = useCallback(async () => {
    if (animalId) {
      try {
        const filters = {
          animal_id: parseInt(animalId),
        } as EvaluationFilterProps

        const data = await getReadAllEvaluations(filters)

        setAnimalEvaluations(data.items)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [animalId])

  const getAnimal = useCallback(
    async (searchTerm: string, type: EvaluationAnimalSearchType) => {
      const filters = {
        stock_number_or_birth_number_or_electronic_eartag: searchTerm,
      } as AnimalFilterProps

      if (type === EvaluationAnimalSearchType.matrix) {
        try {
          const animals = await getReadAllAnimals(filters)

          return animals.items[0]
        } catch (e) {
          handleHttpError(e)
        }
      }

      if (type === EvaluationAnimalSearchType.calf) {
        try {
          const animal = await getReadAnimalByCalf(filters)

          return animal
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const createEvaluation = useCallback(
    async (request: EvaluationCreateRequestData) => {
      if (animalId) {
        try {
          await postCreateEvaluation(request)

          await getAnimalEvaluations()

          addToast({
            message: Messages.EVALUATION_CREATED_SUCCESSFUL,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [animalId, getAnimalEvaluations]
  )

  const exportEvaluations = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const evaluations = await getReadAllEvaluations(
        filters,
        1,
        EXPORT_ANIMALS_SIZE
      )

      const templateFile = generateXlsxTemplate([
        [
          'Nº de Nascimento',
          'Nº de Plantel',
          'Brinco Eletrônico',
          'Sexo',
          'Data da última avaliação',
          'Nota',
          'Avaliação',
          'GMD',
        ],
        ...evaluations?.items.map(
          ({
            birth_number,
            stock_number,
            electronic_eartag,
            sex,
            date,
            score,
            evaluation,
            average_daily_gain,
          }) => [
            birth_number,
            stock_number,
            electronic_eartag ? electronic_eartag : null,
            AnimalSex[sex],
            date ? dateTimeXslxFormat(date) : null,
            AnimalEvaluations[score],
            evaluation,
            average_daily_gain,
          ]
        ),
      ])

      downloadFile({
        data: templateFile,
        fileName: `avaliacoes-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters, setIsLoadingExport])

  useEffect(() => {
    if (!animalId && page) {
      readAllEvaluations()
    }
  }, [animalId, readAllEvaluations, page])

  useEffect(() => {
    if (animalId) {
      getAnimalEvaluations()
    }
  }, [animalId, getAnimalEvaluations])

  return {
    evaluations,
    animalEvaluations,
    getAnimal,
    createEvaluation,
    exportEvaluations,
    isLoading,
    isLoadingExport,
  }
}

export { useEvaluations }
