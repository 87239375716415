import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { ProtocolExpandedInfo } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { NavigateList } from 'app/core/routes/routes'
import { ProtocolHookProps } from 'app/core/types/hooks'
import { ProtocolReadResponseData } from 'app/core/types/hormonal'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  initDate: yup.string(),
  finalDate: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { initDate } = this.parent
        return isEndDateBeforeStartDate(initDate, value)
      }
    ),
})

type ProtocolHistoryTemplateProps = {
  protocols?: ProtocolReadResponseData
  filters: ProtocolHookProps
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  navigateToCreate: () => void
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
}

const ProtocolHistoryTemplate: React.FC<ProtocolHistoryTemplateProps> = ({
  protocols,
  currentPage,
  setCurrentPage,
  navigateToCreate,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  isLoading,
}) => {
  const history = useHistory<NavigateList>()

  const { Form, formState, reset } = useForm({ onSubmit, validationSchema })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
  }

  return (
    <>
      <BoxedLayout
        title="Protocolo Hormonal"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={navigateToCreate}
        currentPage={currentPage}
        totalPages={protocols?.pages}
        setCurrentPage={setCurrentPage}
      >
        {withLoading(
          <>
            <DataCounter
              counter={protocols?.total}
              counterLabel={'Registros'}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div>
              {protocols?.items.map((protocol, i) => (
                <ExpandCard
                  title={protocol.name}
                  singleDate={protocol.date_created}
                  navigation={(): void =>
                    history.push(
                      `${NavigateList.hormonalProtocolDetails}${protocol.id}`
                    )
                  }
                  key={`protocol-${i}`}
                >
                  <ProtocolExpandedInfo data={protocol.actions} />
                </ExpandCard>
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <Form.InputText
              label="Período de Protocolo Hormonal"
              name="initDate"
              htmlType="date"
            />
            <Form.InputText
              emptyLabelSpacing
              name="finalDate"
              htmlType="date"
            />
          </DrawerRow>
          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </>
  )
}

export { ProtocolHistoryTemplate }
