import { AnimalListProps, AnimalSexLabel } from './animal'
import { DefaultPagination } from './system'

export enum EvaluationType {
  C = 1,
  B = 2,
  A = 3,
  APlus = 4,
  AA = 5,
}

export enum EvaluationAnimalSearchType {
  matrix = 'Matriz',
  calf = 'Cria',
}

export const AnimalEvaluations: { [key in EvaluationType]: string } = {
  [EvaluationType.C]: 'C',
  [EvaluationType.B]: 'B',
  [EvaluationType.A]: 'A',
  [EvaluationType.APlus]: 'A+',
  [EvaluationType.AA]: 'AA',
}

export const EVALUATION_OPTIONS = Object.entries(AnimalEvaluations)
  .map(([key, value]) => ({
    label: value,
    value: key,
  }))
  .reverse()

export const EVALUATION_ANIMAL_SEARCH = Object.entries(
  EvaluationAnimalSearchType
).map(([key, value]) => ({
  label: value,
  value: key,
}))

export type EvaluationProps = {
  id: number
  animal_id: number
  date: string
  score: EvaluationType
  average_daily_gain?: number
  weaning_weight?: number
  evaluation?: string
  birth_number?: string
  electronic_eartag?: string
  birth_date?: string
  stock_number?: string
  sex: AnimalSexLabel
}

export type EvaluationFilterProps = {
  animal_name: string
  animal_id: number
  init_date: string
  final_date: string
  sector_id: number
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type EvaluationReadResponseData = {
  items: EvaluationProps[]
} & DefaultPagination

export type EvaluationCreateRequestData = {
  date: string
  score: EvaluationType
  evaluation?: string
  weight?: number
  average_daily_gain?: number
  animal_id: number
}
