import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import { SelectedFiles } from 'use-file-picker'

import { SelectItemProps } from 'components/atoms'

import {
  deleteService as deleteServiceService,
  getAllServices,
  patchUpdateService,
  postCreateService,
  postImportService,
} from 'app/core/services/system/service'
import { ServiceHook } from 'app/core/types/hooks'
import {
  ServiceCreateRequestData,
  ServiceFormStateProps,
  ServiceProps,
  ServiceType,
} from 'app/core/types/system'
import {
  addToast,
  checkImportInconsistencies,
  getPlural,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useService = (
  serviceType?: ServiceType,
  farmId?: string
): ServiceHook => {
  const [services, setServices] = useState<ServiceProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleServiceErrors = (
    serviceName: string,
    serviceFarmName: string,
    error: unknown
  ) => {
    let message = (error as Error).message

    if (axios.isAxiosError(error) && error.response?.data['message']) {
      message = error.response?.data['message']
      addToast({
        message: ` ${serviceName} (${serviceFarmName}): ${message}`,
      })
    } else {
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const servicesDroplist = services.map(service => {
    return {
      label: service.name,
      value: `${service.id}`,
    }
  }) as SelectItemProps[]

  const readAllServices = useCallback(
    async (serviceType?: ServiceType): Promise<void> => {
      try {
        setIsLoading(true)
        const data = await getAllServices({
          type: serviceType,
          farm_id: Number(farmId),
        })

        const sortedServices = [...data].sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()

          return nameA.localeCompare(nameB)
        })

        setServices(sortedServices)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    [farmId]
  )

  const createService = useCallback(
    async (data: ServiceFormStateProps): Promise<void> => {
      const { service, serviceType, farms } = data

      setIsLoading(true)

      const successfulServices = [] as ServiceProps[]

      for (const farm of farms) {
        const farmName = farm.label

        try {
          const request = {
            name: service,
            type: serviceType,
            farm_id: Number(farm.value),
          } as ServiceCreateRequestData

          const data = await postCreateService(request)

          successfulServices.push({
            ...data,
            new: true,
          })

          const oldServices = services.map(service => ({
            ...service,
            new: false,
          }))

          setServices([...successfulServices, ...oldServices])
        } catch (error) {
          handleServiceErrors(service, farmName, error)
        } finally {
          setIsLoading(false)
        }
      }

      if (successfulServices.length > 0) {
        addToast({
          message: `${getPlural(
            'Serviço',
            successfulServices.length,
            true
          )} ${getPlural('criado', successfulServices.length)} com sucesso.`,
          type: 'success',
        })
      }
    },
    [services]
  )

  const updateService = useCallback(
    async (
      serviceId: number,
      request: Partial<ServiceCreateRequestData>
    ): Promise<void> => {
      try {
        const data = await patchUpdateService(serviceId, request)

        setServices(prevServices =>
          prevServices.map(service =>
            service.id === serviceId
              ? { ...data, new: true }
              : { ...service, new: false }
          )
        )

        addToast({
          message: Messages.SYSTEM_SERVICE_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }
    },
    []
  )

  const deleteService = useCallback(
    async (serviceId: number): Promise<void> => {
      try {
        setIsLoading(true)

        await deleteServiceService(serviceId)

        setServices(prevServices =>
          prevServices.filter(service => service.id !== serviceId)
        )

        addToast({
          message: Messages.SYSTEM_SERVICE_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const importService = useCallback(
    async (file: SelectedFiles) => {
      try {
        setIsLoading(true)

        const data = await postImportService(file)

        checkImportInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_SERVICE_IMPORT_SUCCESS,
          type: 'success',
        })

        readAllServices(serviceType)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    [readAllServices, serviceType]
  )

  useEffect(() => {
    readAllServices(serviceType)
  }, [readAllServices, serviceType])

  return {
    services,
    servicesDroplist,
    createService,
    updateService,
    deleteService,
    importService,
    isLoading,
  }
}

export { useService }
