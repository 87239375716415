import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { useForm } from 'components/organisms'

import { useBreedingStation, useFarm, useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { BreedingStationFilterProps } from 'app/core/types/breeding'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

import BreedingStationHistoryTemplate from './template'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
})

const BreedingStationHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<BreedingStationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteBreedingStationId, setDeleteBreedingStationId] =
    useState<number>()
  const { activeFarmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()

  const {
    stations,
    deleteStation,
    isLoading,
    isLoadingExport,
    exportBreedingStation,
  } = useBreedingStation({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterGroups = async (data: unknown): Promise<void> => {
    const dataCopy = data as BreedingStationFilterProps

    setPage(1)
    setFilters(dataCopy)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteBreedingStationId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteBreedingStationId) {
      await deleteStation(deleteBreedingStationId)

      handleDeleteModalClose()
    }
  }, [deleteBreedingStationId, deleteStation])

  const { Form, formState, reset } = useForm({ onSubmit: handleFilterGroups, validationSchema })

  const activeFilters = Object.entries(formState.touchedFields).length

  const handleResetFilters = (): void => {
    setFilters(undefined)
    reset()
  }

  const history = useHistory<NavigateList>()

  const handleDetailsClick = (stationId: number): void => {
    history.push(`${NavigateList.breedingStationDetails}${stationId}`)
  }

  const handleCreateClick = (): void => {
    history.push(NavigateList.breedingStationCreate)
  }

  return (
    <BreedingStationHistoryTemplate
      stations={stations}
      setPage={setPage}
      farms={activeFarmsDroplist}
      sectors={sectorsDroplist}
      page={page}
      isLoadingExport={isLoadingExport}
      onExportClick={exportBreedingStation}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      isLoading={isLoading}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      deleteBreedingStationId={deleteBreedingStationId}
      setDeleteBreedingStationId={setDeleteBreedingStationId}
      onDetailsClick={handleDetailsClick}
      onCreateClick={handleCreateClick}
      activeFilters={activeFilters}
      onResetFilters={handleResetFilters}
      form={Form}
    />
  )
}

export { BreedingStationHistory }
