/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, FieldValues, useController } from 'react-hook-form'

import {
  Icon,
  IconNames,
  InputDebounced,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { ClassificationProps, CostCenterProps } from 'app/core/types/system'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type TreeViewItem = ClassificationProps | CostCenterProps

export type TreeViewProps = {
  containerClassName?: string
  control?: Control<FieldValues>
  defaultExpanded?: boolean
  errorMessage?: string
  fieldName?: string
  items: CostCenterProps[] | ClassificationProps[]
  onSelect?: (id: number) => void
  testId?: string
  value?: number
}

const TreeView: React.FC<TreeViewProps> = ({
  containerClassName,
  control,
  defaultExpanded,
  errorMessage,
  fieldName,
  items,
  onSelect,
  testId,
  value,
}) => {
  const isListView = !control && !value
  const itemsArray = items as (CostCenterProps | ClassificationProps)[]

  const { field } =
    !isListView && fieldName
      ? useController({
          name: fieldName,
          control,
          defaultValue: value || null,
        })
      : {
          field: {
            onChange: (): void => {
              null
            },
          },
        }

  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    defaultExpanded
      ? itemsArray.reduce((acc, item) => ({ ...acc, [item.id]: true }), {})
      : {}
  )
  const [selectedItemId, setSelectedItemId] = useState<number | null>(
    value || null
  )
  const [searchTerm, setSearchTerm] = useState<string | number>('')

  const filteredItemsBySearch = useMemo(() => {
    return itemsArray.filter(item => {
      const searchValue = searchTerm.toString().toLowerCase()
      return (
        item.name.toString().toLowerCase().includes(searchValue) ||
        (item as TreeViewItem).code
          .toString()
          .toLowerCase()
          .includes(searchValue)
      )
    })
  }, [itemsArray, searchTerm])

  const getParentId = (item: TreeViewItem): number | null => {
    return (
      (item as ClassificationProps).parent_classification_id ??
      (item as CostCenterProps).parent_cost_center_id
    )
  }

  useEffect(() => {
    if (value) {
      const expandParents = (id: number): void => {
        const item = itemsArray.find(item => item.id === id)
        if (item) {
          const parentId = getParentId(item)

          if (parentId) {
            setExpandedItems(prev => ({ ...prev, [parentId]: true }))
            expandParents(parentId)
          }
        }
      }

      expandParents(value)
      setSelectedItemId(value)
    }
  }, [value, itemsArray])

  const handleToggle = (id: number): void => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const handleSelect = (id: number): void => {
    setSelectedItemId(id)
    field.onChange(id)
    onSelect?.(id)
  }

  const buildItemsByParent = useCallback(
    (items: TreeViewItem[]): Record<number, TreeViewItem[]> => {
      const parentMap: Record<number, TreeViewItem[]> = {}

      const addToParentMap = (
        map: Record<number, TreeViewItem[]>,
        parentId: number,
        item: TreeViewItem
      ): void => {
        if (!map[parentId]) map[parentId] = []

        map[parentId].push(item)
      }

      const isParentMissing = (
        parentId: number | null,
        items: TreeViewItem[]
      ): boolean => {
        return (
          parentId === null ||
          !items.some(parentItem => parentItem.id === parentId)
        )
      }

      items.forEach(item => {
        const parentId = getParentId(item)

        if (parentId) addToParentMap(parentMap, parentId, item)
        if (isParentMissing(parentId, items)) addToParentMap(parentMap, 0, item)
      })

      return parentMap
    },
    []
  )

  const filteredItemsByParent = useMemo(() => {
    return buildItemsByParent(filteredItemsBySearch)
  }, [buildItemsByParent, filteredItemsBySearch])

  const renderItems = (parentId: number | null): JSX.Element => {
    const filteredItems = filteredItemsByParent[parentId || 0] || []

    return (
      <ol className={styles.treeView}>
        {filteredItems.map(item => {
          const hasChildren = !!filteredItemsByParent[item.id]
          const isExpanded = expandedItems[item.id]

          return (
            <li key={item.id}>
              <label
                className={selectedItemId === item.id ? styles.selected : ''}
                data-testid={`label-${fieldName}-${item.id}`}
                htmlFor={`input-${fieldName}-${item.id}`}
                onClick={(): void => hasChildren && handleToggle(item.id)}
              >
                {hasChildren && (
                  <Icon
                    size={16}
                    name={
                      isExpanded
                        ? IconNames['chevron-down']
                        : IconNames['chevron-forward']
                    }
                  />
                )}
                {!hasChildren && !isListView && (
                  <input
                    data-testid={`input-${fieldName}-${item.id}`}
                    id={`input-${fieldName}-${item.id}`}
                    type="radio"
                    name={fieldName}
                    checked={selectedItemId === item.id}
                    onChange={(): void => handleSelect(item.id)}
                  />
                )}
                {item.name}{' '}
                {!hasChildren && (
                  <span className={styles.itemCode}>{item.code}</span>
                )}
              </label>

              {hasChildren && isExpanded && renderItems(item.id)}
            </li>
          )
        })}
      </ol>
    )
  }

  const renderFirstLevelItems = (): JSX.Element => {
    return renderItems(null)
  }

  const handleSearchInputReset = (): void => {
    setSearchTerm('')
    setExpandedItems({})
  }

  return (
    <div className={containerClassName} data-testid={testId}>
      <InputDebounced
        autoFocus={isListView}
        containerClassName={styles.searchInput}
        handleReset={handleSearchInputReset}
        initialValue=""
        onChange={setSearchTerm}
        placeholder="Buscar itens"
      />
      {filteredItemsBySearch.length === 0 ? (
        <Typography
          className={styles.emptyList}
          text={Messages.SYSTEM_PARAMETERS_EMPTY_DATA}
          variant={TypographyVariant.p}
        />
      ) : (
        <>
          {renderFirstLevelItems()}
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
        </>
      )}
    </div>
  )
}

export { TreeView }
