import { useCallback, useEffect, useState } from 'react'

import {
  deleteGroupDiagnostic,
  getDiagnostics,
  getDiagnosticsReport,
} from 'app/core/services'
import {
  DiagnosticGroupHookProps,
  DiagnosticReadResponseData,
} from 'app/core/types/breeding'
import { DiagnosticGroupHook } from 'app/core/types/hooks'
import { InseminationTypes } from 'app/core/types/hormonal'
import { DEFAULT_ITEMS_PER_PAGE_LIST } from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  downloadFile,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useDiagnosticGroup = ({
  filters,
  page,
}: DiagnosticGroupHookProps): DiagnosticGroupHook => {
  const [groups, setGroups] = useState<DiagnosticReadResponseData>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const getDiagnosticGroups = useCallback(async () => {
    try {
      setIsLoading(true)

      const diagnostics = await getDiagnostics(
        filters,
        page,
        DEFAULT_ITEMS_PER_PAGE_LIST
      )

      setGroups(diagnostics)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      handleHttpError(e, false)
    }
  }, [filters, page])

  const deleteDiagnosticGroup = useCallback(
    async (diagonsticGroupId: number) => {
      try {
        await deleteGroupDiagnostic(diagonsticGroupId)

        getDiagnosticGroups()

        addToast({
          message: Messages.BREEDING_DIAGNOSTICS_GROUP_DELETED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }
    },
    [getDiagnosticGroups]
  )

  const exportDiagnosticGroup = useCallback(async () => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getDiagnosticsReport(
        filters,
        page,
        DEFAULT_ITEMS_PER_PAGE_LIST
      )

      const diagnostics = data.map(diagnostic => ({
        'Data do grupo': getFormattedDateValueOrNull(
          diagnostic.diagnostic_group_date
        ),
        Responsável: String(diagnostic.responsible ?? ''),
        'Brinco eletrônico': String(diagnostic.electronic_eartag ?? ''),
        'Nº de nascimento': getValueOrNull(diagnostic.birth_number),
        'Nº de plantel': getValueOrNull(diagnostic.stock_number),
        Diagnóstico: diagnostic.diagnostic_status ? 'Positivo' : 'Negativo',
        'Data do diagnóstico': getFormattedDateValueOrNull(
          diagnostic.diagnostic_date
        ),
        'Tipo de diagnóstico': diagnostic.diagnostic_type
          ? diagnostic.diagnostic_type === 'Inseminação'
            ? InseminationTypes.artificial_insemination
            : InseminationTypes.natural_cover
          : null,
        'Data de inseminação': getFormattedDateValueOrNull(
          diagnostic.insemination_date
        ),
        'Dias de gestação': String(diagnostic.gestation_days ?? ''),
        'Previsão de nascimento': getFormattedDateValueOrNull(
          diagnostic.birth_forecast
        ),
      }))

      const blob = arrayToCsv(diagnostics, ';')

      downloadFile({
        data: blob,
        fileName: `diagnosticos-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.BREEDING_DIAGNOSTICS_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }, [page, filters])

  useEffect(() => {
    getDiagnosticGroups()
  }, [getDiagnosticGroups])

  return {
    getDiagnosticGroups,
    groups,
    isLoading,
    deleteDiagnosticGroup,
    exportDiagnosticGroup,
    isLoadingExport,
  }
}

export { useDiagnosticGroup }
