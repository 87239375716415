import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { useForm } from 'components/organisms'

import {
  useBreedingStation,
  useFarm,
  useSchedules,
  useSector,
} from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { ScheduleFilterProps } from 'app/core/types/hormonal'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

import { ScheduleHistoryTemplate } from './template'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
})

const ScheduleHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<ScheduleFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedScheduleId, setSelectedScheduleId] = useState<
    number | undefined
  >(undefined)

  const { activeFarmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()
  const { stationsDroplistOptions } = useBreedingStation({})

  const {
    schedules,
    isLoading,
    deleteSchedule,
    exportSchedules,
    isLoadingExport,
  } = useSchedules({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterSchedules = async (data: unknown): Promise<void> => {
    setFilters(data as ScheduleFilterProps)
    handleToggleDrawer()
  }

  const handleToggleModal = useCallback(
    (scheduleId?: number): void => {
      setSelectedScheduleId(scheduleId)
      setIsModalOpen(!isModalOpen)
    },
    [isModalOpen]
  )

  const handleDeleteModalConfirm = useCallback(async () => {
    if (selectedScheduleId) {
      deleteSchedule(selectedScheduleId)
      handleToggleModal(undefined)
    }
  }, [deleteSchedule, selectedScheduleId, handleToggleModal])

  const history = useHistory<NavigateList>()

  const { Form, formState, reset } = useForm({
    onSubmit: handleFilterSchedules,
    validationSchema,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    setFilters({} as ScheduleFilterProps)
    reset()
  }

  const handleCreateClick = (): void => {
    history.push(NavigateList.iatfScheduleCreate)
  }

  const handleScheduleClick = (id: number): void => {
    history.push(`${NavigateList.iatfScheduleDetails}${id}`)
  }

  return (
    <ScheduleHistoryTemplate
      schedules={schedules}
      farms={activeFarmsDroplist}
      sectors={sectorsDroplist}
      breedingStations={stationsDroplistOptions}
      currentPage={page}
      setCurrentPage={setPage}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      onSubmit={handleFilterSchedules}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      handleToggleModal={handleToggleModal}
      onDeleteClick={handleDeleteModalConfirm}
      onCreateClick={handleCreateClick}
      onScheduleClick={handleScheduleClick}
      form={Form}
      activeFilters={activeFilters}
      onResetFilterClick={resetFilters}
      onExportClick={exportSchedules}
      isLoadingExport={isLoadingExport}
    />
  )
}

export { ScheduleHistory }
