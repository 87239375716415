import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { OSLayout } from 'components/templates'

import styles from './styles.module.scss'
import {
  ClassificationTab,
  CostCenterTab,
  ExecuterTab,
  FarmsTab,
  LocationTab,
  MachineryTab,
  ServiceTypesTab,
  ServicesTab,
} from './tabs'

export enum TabsTypes {
  services = 'services',
  serviceTypes = 'serviceTypes',
  location = 'location',
  farms = 'farms',
  executer = 'executer',
  machinery = 'machinery',
  classification = 'classification',
  costCenter = 'costCenter',
}

type TabButtonProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  onClick: (value: TabsTypes) => void
  testId?: string
}

type ParameterTab = {
  label: string
  type: TabsTypes
  testId: string
  isLocalParameter?: boolean
}

const parametersTabs: ParameterTab[] = [
  {
    label: 'Serviços',
    type: TabsTypes.services,
    testId: 'servicesTab',
    isLocalParameter: true,
  },
  {
    label: 'Tipos de serviços',
    type: TabsTypes.serviceTypes,
    testId: 'servicesTypeTab',
    isLocalParameter: true,
  },
  {
    label: 'Locais',
    type: TabsTypes.location,
    testId: 'locationTab',
    isLocalParameter: true,
  },
  {
    label: 'Fazendas',
    type: TabsTypes.farms,
    testId: 'farmsTab',
    isLocalParameter: true,
  },
  { label: 'Executores', type: TabsTypes.executer, testId: 'executerTab' },
  { label: 'Maquinários', type: TabsTypes.machinery, testId: 'machineryTab' },
  {
    label: 'Centros de custo',
    type: TabsTypes.costCenter,
    testId: 'costCenterTab',
  },
  {
    label: 'Classificações',
    type: TabsTypes.classification,
    testId: 'classificationsTab',
  },
]

const TabButton: React.FC<TabButtonProps> = ({
  activeTab,
  tabType,
  onClick,
  children,
  testId,
}) => (
  <li
    data-testid={testId}
    className={classNames(
      styles.tab,
      activeTab === tabType && styles.tabActive
    )}
    onClick={(): void => onClick(tabType)}
  >
    <span>{children}</span>
    <Icon name={IconNames['chevron-forward']} />
  </li>
)

type TabPageProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  children: React.ReactElement
}

const TabPage: React.FC<TabPageProps> = ({ activeTab, tabType, children }) =>
  activeTab === tabType ? children : null

const ParametersTemplate: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState<TabsTypes>(TabsTypes.services)

  const handleTabClick = async (tab: TabsTypes): Promise<void> => {
    if (tab === activeTab) return
    setActiveTab(tab)
    history.push(`?tab=${tab}`)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab') as TabsTypes

    if (tab && Object.values(TabsTypes).includes(tab)) setActiveTab(tab)
  }, [location.search])

  return (
    <OSLayout>
      <Typography text="Parâmetros do Sistema" variant={TypographyVariant.h4} />
      <section className={styles.parameters}>
        <aside className={styles.parametersSidebar}>
          <Typography text="Parâmetros locais" variant={TypographyVariant.h5} />
          <nav>
            <ul className={styles.tabList}>
              {parametersTabs
                .filter(({ isLocalParameter }) => isLocalParameter)
                .map(({ label, type, testId }) => (
                  <TabButton
                    key={testId}
                    testId={testId}
                    activeTab={activeTab}
                    tabType={type}
                    onClick={handleTabClick}
                  >
                    {label}
                  </TabButton>
                ))}
            </ul>
          </nav>

          <hr className={styles.parametersSidebarDivider} />

          <Typography
            text="Parâmetros importados do seu sistema ERP"
            variant={TypographyVariant.h5}
          />

          <nav>
            <ul className={styles.tabList}>
              {parametersTabs
                .filter(({ isLocalParameter }) => !isLocalParameter)
                .map(({ label, type, testId }) => (
                  <TabButton
                    key={testId}
                    testId={testId}
                    activeTab={activeTab}
                    tabType={type}
                    onClick={handleTabClick}
                  >
                    {label}
                  </TabButton>
                ))}
            </ul>
          </nav>
        </aside>
        <div className={styles.parametersList}>
          <TabPage activeTab={activeTab} tabType={TabsTypes.services}>
            <ServicesTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.serviceTypes}>
            <ServiceTypesTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.location}>
            <LocationTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.farms}>
            <FarmsTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.executer}>
            <ExecuterTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.machinery}>
            <MachineryTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.classification}>
            <ClassificationTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.costCenter}>
            <CostCenterTab />
          </TabPage>
        </div>
      </section>
    </OSLayout>
  )
}

export { ParametersTemplate }
