/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse'

import { AnimalFilterProps } from 'app/core/types/animal'
import {
  IATFReportResponse,
  ScheduleAnimalProps,
  ScheduleCreateRequestData,
  ScheduleCreateResponseData,
  ScheduleFilterProps,
  ScheduleProps,
  ScheduleReadResponseData,
  ScheduleUpdateAnimalRequestData,
} from 'app/core/types/hormonal'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/hormonal/iatf_schedule'

const getReadAllSchedules = async (
  filters?: ScheduleFilterProps,
  page?: number,
  size?: number
): Promise<ScheduleReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadSchedule = async (
  scheduleId?: string | number
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/${scheduleId}/`
  const response = await http.get(url)
  return response.data
}

const getReadAnimalsBySchedule = async (
  scheduleId: string | number,
  filters?: Partial<AnimalFilterProps>
): Promise<ScheduleAnimalProps[]> => {
  const url = `${baseUrl}/${scheduleId}/animals/`

  const response = await http.get(url, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postCreateSchedule = async (
  request: ScheduleCreateRequestData
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/`

  const response = await http.post(url, request)
  return response.data
}

const postUpdateScheduleAnimal = async (
  request: ScheduleUpdateAnimalRequestData[]
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/bulk/`

  const response = await http.post(url, request)
  return response.data
}

const postAddScheduleAnimal = async (
  scheduleId: string | number,
  animalId: number
): Promise<ScheduleCreateResponseData> => {
  const url = `${baseUrl}/${scheduleId}/animal/${animalId}/`

  const data: Promise<ScheduleCreateResponseData> = await http
    .post(url)
    .then(res => res.data)
    .catch(e => e.response.data)

  return data
}

const deleteRemoveScheduleAnimal = async (
  scheduleId: string | number,
  animalId: number
): Promise<void> => {
  const url = `${baseUrl}/${scheduleId}/animal/${animalId}/`

  const response = await http.delete(url)
  return response.data
}

const deleteRemoveSchedule = async (
  scheduleId: string | number
): Promise<void> => {
  const url = `${baseUrl}/${scheduleId}/`

  const response = await http.delete(url)
  return response.data
}

const getIATFScheduleReport = async (
  filters: ScheduleFilterProps
): Promise<IATFReportResponse[]> => {
  const response = await http.get(`${baseUrl}/report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const schedules: IATFReportResponse[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    schedules.push({
      id: Number(item[0]),
      name: item[1],
      date_start: new Date(item[2]),
      date_end: new Date(item[3]),
      breeding_station_id: item[4] ? Number(item[4]) : undefined,
      breeding_station_name: item[5],
      hormonal_protocol_id: item[6] ? Number(item[6]) : undefined,
      hormonal_protocol_name: item[7],
      animal_id: item[8] ? Number(item[8]) : undefined,
      stock_number: item[9],
      electronic_eartag: item[10],
      birth_number: item[11],
      iatf_schedule_animal_id: item[12] ? Number(item[12]) : undefined,
      body_condition_score: item[13] ? Number(item[13]) : undefined,
      implanted: item[14] ? item[14] === 'True' : undefined,
      implant_used: item[15],
      date_created: item[16] ? new Date(item[16]) : undefined,
      actions: item[17],
      last_calving: item[18] ? new Date(item[18]) : undefined,
    })
  }

  return schedules
}

export {
  deleteRemoveSchedule,
  deleteRemoveScheduleAnimal,
  getIATFScheduleReport,
  getReadAllSchedules,
  getReadAnimalsBySchedule,
  getReadSchedule,
  postAddScheduleAnimal,
  postCreateSchedule,
  postUpdateScheduleAnimal,
}
