import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBirth } from 'app/core/hooks/births'
import { NavigateList } from 'app/core/routes/routes'
import { BirthsFilterProps } from 'app/core/types/birth'

import { BirthHistoryTemplate } from './template'

const BirthHistory: React.FC = () => {
  const history = useHistory()

  const navigateTo = (): void => history.push(NavigateList.birthCreate)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<BirthsFilterProps>()
  const [page, setPage] = useState<number>(1)

  const { births, exportBirths, isLoading, isLoadingExport, deleteBirth } =
    useBirth({ filters, page })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterBirth = useCallback(
    async (data: unknown): Promise<void> => {
      const dataCopy = data as BirthsFilterProps

      setPage(1)
      setFilters(dataCopy)
      handleToggleDrawer()
    },
    []
  )

  const handleDeleteBirth = useCallback(
    async (birthId: number): Promise<void> => {
      deleteBirth(birthId)
    },
    [deleteBirth]
  )

  const navigateToAnimalDetails = (animalId: number): void => {
    window.open(`${NavigateList.animalRecord}${animalId}`, '_blank')
  }

  const handleFatherNavigation = (id: number, type: string): void => {
    if (type === 'Animal') return navigateToAnimalDetails(id)

    window.open(`${NavigateList.breedingGroupDetails}${id}`, '_blank')
  }

  return (
    <BirthHistoryTemplate
      births={births}
      currentPage={page}
      exportBirths={exportBirths}
      setCurrentPage={setPage}
      navigateToCreate={navigateTo}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterBirth}
      setFilters={setFilters}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
      handleDeleteBirth={handleDeleteBirth}
      onMotherStockNumberClick={navigateToAnimalDetails}
      onCalfNameClick={navigateToAnimalDetails}
      onFatherClick={handleFatherNavigation}
    />
  )
}

export { BirthHistory }
