/* eslint-disable no-console */
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import {
  useAssociation,
  useBreed,
  useGetImportAnimalTemplate,
  useImportAnimal,
} from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalCreateProps } from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'
import { addToast, animalsEmptyFields } from 'app/core/utils'
import { Messages } from 'config/messages'

import { animalCreateColumns, animalEditableColumns } from '../tables'
import { createDefaultEmptyRow } from './consts'
import { AnimalCreateTemplate } from './template'

const AnimalCreate: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { breeds, breedsDroplist } = useBreed()
  const { associationsDroplist } = useAssociation({})

  const savedSector = JSON.parse(
    localStorage.getItem(StorageKeys.animals_sector_review) as string
  ) as SectorProps
  const allAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.animals_review) as string
  ) as AnimalCreateProps[]
  const savedAnimals = allAnimals?.some(e => e.error_message)
    ? allAnimals.filter(e => e.error_message)
    : allAnimals

  if (!savedSector) {
    history.push(NavigateList.animalList)
  }

  const [animals, setAnimals] = useState<AnimalCreateProps[]>(
    () => savedAnimals || createDefaultEmptyRow(savedSector.id)
  )

  const handleAddRow = (): void => {
    setAnimals(prevAnimals => [
      ...prevAnimals,
      ...createDefaultEmptyRow(savedSector.id),
    ])
  }

  const handleGoToReview = (): void => {
    const requiredFields = [
      'stock_number',
      'birth_date',
      'birth_weight',
      'current_weight',
      'sex',
    ]

    const animalErrors = animalsEmptyFields(animals, requiredFields)

    if (animalErrors.length) {
      animalErrors?.forEach(invalidAnimal => {
        setAnimals(prevRows => {
          return prevRows.map(row => {
            if (row.id === invalidAnimal) {
              return {
                ...row,
                error_message: Messages.REQUIRED_FIELDS,
              }
            }
            return row
          })
        })
      })

      addToast({ message: Messages.REQUIRED_FIELDS })
      return
    }

    if (!animals.length) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    const cleanErrorMessages = animals.map(e => {
      delete e.error_message
      return e
    })

    localStorage.setItem(
      StorageKeys.animals_review,
      JSON.stringify(cleanErrorMessages)
    )

    history.push(NavigateList.animalReview)
  }

  const defaultColumn = useMemo<Partial<ColumnDef<AnimalCreateProps>>>(
    () =>
      animalEditableColumns(breedsDroplist || [], associationsDroplist || []),
    [breedsDroplist, associationsDroplist]
  )

  const columns = useMemo<ColumnDef<AnimalCreateProps>[]>(
    () => animalCreateColumns({ animals, setAnimals }),
    [animals]
  )

  const table = useReactTable({
    data: animals || [],
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setAnimals(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  useEffect(() => {
    localStorage.setItem(StorageKeys.animals_review, JSON.stringify(animals))
  }, [animals])

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const importAnimal = useImportAnimal
  const getImportAnimalTemplate = useGetImportAnimalTemplate

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    setAnimals([])

    const animals = await importAnimal({
      sectorId: savedSector.id,
      file: selectedFile,
      breeds: breeds ?? [],
    })

    if (animals) setAnimals(animals)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        getImportAnimalTemplate()
      },
    },
  ]

  return (
    <AnimalCreateTemplate
      handleAddRow={handleAddRow}
      handleGoToReview={handleGoToReview}
      sector={savedSector.name}
      animals={animals}
      table={table}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { AnimalCreate }
