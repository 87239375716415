/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DeepMap, FieldValues } from 'react-hook-form'
import { OptionsType } from 'react-select'

import classNames from 'classnames'
import { addDays, subDays } from 'date-fns'
import { useAuthUser } from 'providers/auth-user'
import { useServiceOrder } from 'providers/service-orders'

import {
  Button,
  ButtonSize,
  ButtonType,
  GroupedOption,
  IRadioButtonOption,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
} from 'components/molecules'
import { TreeView, useForm } from 'components/organisms'
import { getDirtyValues } from 'components/organisms/form/helpers'
import {
  DurationSuggestions,
  FarmChip,
  GroupLabel,
  ServiceOption,
} from './components'

import {
  useClassification,
  useCostCenter,
  useExecuter,
  useFarm,
  useLocation,
  useMachinery,
  useService,
  useSuggestion,
} from 'app/core/hooks'
import { SuggestionRequestData } from 'app/core/services/system/classification-cost-center-suggestion'
import {
  ServiceOrderDraftInfoProps,
  ServiceOrderProps,
} from 'app/core/types/service-order'
import { ServiceType } from 'app/core/types/system'
import {
  dateTimeFormat,
  parseDateToISO,
  parseMinutesToHours,
  removeEmptyFilters,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { ServiceOrderHistory } from '../history'
import {
  formatExecutionDates,
  formatServiceOrder,
  validationSchema,
} from './helpers'
import styles from './styles.module.scss'

export enum OSDrawerMode {
  create = 'create',
  edit = 'edit',
}

type OSDrawerProps = {
  isDrawerOpen: boolean
  toggleDrawer: () => void
  mode?: OSDrawerMode
  editItem?: ServiceOrderDraftInfoProps
}

type TabsProps = {
  label: string
}

const OSDrawer: React.FC<OSDrawerProps> = ({
  isDrawerOpen,
  toggleDrawer,
  mode,
  editItem,
}) => {
  const isEditing = mode === OSDrawerMode.edit
  const maxBulletinNumberLength = 10
  const selectedDateIndex = 3
  const datesOptionLength = 4
  const editTabIndex = 0
  const historyTabIndex = 1
  const inputDateRef = useRef<HTMLInputElement>(null)

  const defaultExecutionDates = Array(datesOptionLength)
    .fill(new Date())
    .map((currentDate, day) => subDays(currentDate, day))

  const { createServiceOrder, updateServiceOrder } = useServiceOrder()

  const { selectedFarm, setSelectedFarm } = useAuthUser()
  const { activeFarms: farms, activeFarmsDroplist } = useFarm()

  const [operatedMachine, setOperatedMachine] = useState(false)
  const [odometer, setOdometer] = useState({ kmInitial: 0, kmFinal: 0 })
  const [serviceType, setServiceType] = useState(ServiceType.generalService)
  const [serviceAndLocal, setServiceAndLocal] =
    useState<SuggestionRequestData>()
  const [modifiedFields, setModifiedFields] = useState<
    DeepMap<FieldValues, true>
  >({})
  const [bulletinNumber, setBulletinNumber] = useState('')
  const [bulletinDate, setBulletinDate] = useState<IRadioButtonOption[]>(
    formatExecutionDates(defaultExecutionDates)
  )
  const [dateHasChangedManually, setDateHasChangedManually] = useState(false)
  const [durationTime, setDurationTime] = useState('01:00')
  const [executersFilter, setExecutersFilter] = useState<string | undefined>(
    selectedFarm?.id
  )
  const [machineryFilter, setMachineryFilter] = useState<string | undefined>(
    selectedFarm?.id
  )
  const [activeTabIndex, setActiveTabIndex] = useState(editTabIndex)

  const { executers, executersDroplist } = useExecuter(executersFilter)
  const { machineries, machineriesDroplist } = useMachinery(machineryFilter)
  const { services, servicesDroplist } = useService(
    serviceType,
    selectedFarm?.id
  )
  const { suggestion } = useSuggestion(serviceAndLocal)
  const { locations, locationsDroplist } = useLocation(selectedFarm?.id)

  const hasServiceAndLocal =
    serviceAndLocal?.serviceId && serviceAndLocal?.locationId
  const showForm = !isEditing || activeTabIndex === editTabIndex
  const hasSuggestion = Object.keys(suggestion?.classification ?? {}).length > 0

  const tabs: TabsProps[] = [
    {
      label: 'Edição do boletim',
    },
    {
      label: 'Histórico do boletim',
    },
  ]

  const serviceTypeRadio: IRadioButtonOption[] = [
    {
      label: ServiceType.generalService,
      value: ServiceType.generalService,
    },
    {
      label: ServiceType.mechanization,
      value: ServiceType.mechanization,
    },
  ]

  const executorGroupedOptions: GroupedOption[] = [
    {
      label: executersFilter
        ? `Executores da Fazenda ${selectedFarm?.name}`
        : 'Todos executores',
      options: executersDroplist,
    },
  ]

  const machineriesGroupedOptions: GroupedOption[] = [
    {
      label: machineryFilter
        ? `Maquinários da Fazenda ${selectedFarm?.name}`
        : 'Todos maquinários',
      options: machineriesDroplist,
    },
  ]

  const serviceGroupedOptions: GroupedOption[] = [
    {
      label: serviceType,
      options: servicesDroplist,
    },
  ]

  const operatedMachineOptions: IRadioButtonOption[] = useMemo(
    () => [
      {
        label: 'Sim, operou',
        value: 'yes',
      },
      {
        label: 'Não operou',
        value: 'no',
      },
    ],
    []
  )

  const formattedOptionWithFarm: (
    option: SelectItemProps,
    entity: 'executer' | 'machinery'
  ) => void = ({ label, subLabel, value }, entity) => (
    <div className={styles.formatOption}>
      <div className={styles.formatOptionWithChip}>
        <div>{label}</div>
        {subLabel && (
          <div className={styles.formatOptionWithChipSubLabel}>
            {subLabel}
            <FarmChip
              itemId={value}
              itemEntity={entity === 'machinery' ? machineries : executers}
            />
          </div>
        )}
      </div>
    </div>
  )

  const handleOtherDate = (): void => {
    if (inputDateRef.current) {
      inputDateRef.current.showPicker()
    }
  }

  const handleExecutionDate = useCallback((date: string): void => {
    let newDate = new Date(date)
    newDate = addDays(newDate, 1)
    const dateOptions = Array(datesOptionLength)
      .fill(newDate)
      .map((currentDate, day) => subDays(currentDate, day))

    const newDates = formatExecutionDates(dateOptions)

    setBulletinDate(newDates)
    setValue('date', newDates[selectedDateIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBulletin = useCallback(
    async (data: Record<string, unknown>) => {
      const serviceOrder = removeEmptyFilters(data, ['observation'])
      let serviceOrderFormatted = formatServiceOrder(serviceOrder)

      if (isEditing && editItem) {
        const modifiedFieldsValues = getDirtyValues(
          serviceOrder,
          modifiedFields
        )

        if (
          Object.keys(modifiedFieldsValues).length === 0 &&
          !dateHasChangedManually
        )
          return

        const updatedDate =
          modifiedFieldsValues.date !== undefined || dateHasChangedManually
            ? {
                date: parseDateToISO(
                  dateHasChangedManually
                    ? bulletinDate[selectedDateIndex].value
                    : (modifiedFieldsValues.date as string)
                ),
              }
            : {}

        serviceOrderFormatted = formatServiceOrder({
          ...modifiedFieldsValues,
          ...((modifiedFieldsValues.initial_mileage !== undefined ||
            modifiedFieldsValues.final_mileage !== undefined) && {
            odometer: data.odometer,
          }),
          ...updatedDate,
        })

        await updateServiceOrder({
          serviceOrderId: editItem.id,
          payload: serviceOrderFormatted,
        })
      } else {
        await createServiceOrder(serviceOrderFormatted as ServiceOrderProps)
      }

      toggleDrawer()
      resetForm()
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bulletinDate,
      createServiceOrder,
      dateHasChangedManually,
      isEditing,
      editItem,
      modifiedFields,
      updateServiceOrder,
      toggleDrawer,
    ]
  )

  const {
    Form: BulletinForm,
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    onSubmit: handleBulletin,
    validationSchema,
  })

  const costCenterId = watch('cost_center_id')
  const classificationId = watch('classification_id')

  const { costCenters } = useCostCenter(selectedFarm?.id)
  const { classifications } = useClassification({
    costCenterId:
      costCenters?.find(costCenter => costCenter.id === costCenterId)
        ?.parent_cost_center_id || undefined,
    farmId: Number(selectedFarm?.id),
  })

  const costCentersList = useMemo(() => costCenters, [costCenters])
  const classificationsList = useMemo(() => classifications, [classifications])

  const costCenterSelected = costCentersList?.find(
    costCenter => costCenter.id === costCenterId
  )?.name
  const classificationSelected = classificationsList?.find(
    classification => classification.id === classificationId
  )?.name

  const resetForm = useCallback((): void => {
    reset()
    setServiceAndLocal(undefined)
    setBulletinNumber('')
  }, [reset, setServiceAndLocal])

  const handleBulletinAndContinue = useCallback(
    async (data: Record<string, unknown>) => {
      const serviceOrder = removeEmptyFilters(data)
      const serviceOrderFormatted = formatServiceOrder(serviceOrder)
      await createServiceOrder(serviceOrderFormatted as ServiceOrderProps)
      resetForm()
    },
    [resetForm, createServiceOrder]
  )

  const handleBulletinChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const inputValue = e.target.value

    if (inputValue.length <= maxBulletinNumberLength) {
      setBulletinNumber(inputValue)
    }
  }

  const handleFarmChange = (
    e: SelectItemProps | OptionsType<SelectItemProps> | null
  ): void => {
    const selectedFarmId = (e as SelectItemProps)?.value

    setValue('location_id', '')
    setSelectedFarm({
      id: selectedFarmId,
      name: (e as SelectItemProps)?.label,
    })
    setExecutersFilter(selectedFarmId)
    setMachineryFilter(selectedFarmId)
  }

  const handleMachineryChange = (
    e: SelectItemProps | OptionsType<SelectItemProps> | null
  ): void => {
    if (!machineries) return

    const machineryId = (e as SelectItemProps)?.value
    const selectedMachinery = machineries.find(
      machinery => machinery.id === Number(machineryId)
    )

    if (selectedMachinery) {
      setOdometer(prevState => ({
        ...prevState,
        kmInitial: selectedMachinery?.current_mileage,
        kmFinal: 0,
      }))
      setValue('initial_mileage', selectedMachinery?.current_mileage)
      setValue('final_mileage', '')
    }
  }

  const handleMileageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'initial_mileage' | 'final_mileage'
  ): void => {
    const maxMileageLength = 10
    let inputValue = e.currentTarget.value
    inputValue = inputValue.replace(/\D/g, '').slice(0, maxMileageLength)

    if (fieldName === 'initial_mileage') {
      setOdometer({
        kmInitial: Number(inputValue),
        kmFinal: odometer.kmFinal,
      })
    }

    if (fieldName === 'final_mileage') {
      setOdometer({
        kmInitial: odometer.kmInitial,
        kmFinal: Number(inputValue),
      })
    }

    setValue(fieldName, inputValue)
  }

  const isMachineryUsedChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isMachineryUsed = e.currentTarget.value === 'yes'

    setOperatedMachine(isMachineryUsed ? true : false)

    if (!isMachineryUsed) setValue('machinery_id', undefined)
  }

  const validateOptionalFields = useCallback(
    (
      field: 'bulletin_number' | 'cost_center_id' | 'classification_id',
      value: unknown
    ) => {
      if (!value) {
        setError(field, { message: Messages.YUP_REQUIRED_FIELD })
        return
      }

      if (field === 'bulletin_number') setBulletinNumber(value as string)
      setValue(field, value)
    },
    [setError, setValue]
  )

  useEffect(() => {
    if (selectedFarm && !isEditing) {
      const farmProps = activeFarmsDroplist?.find(
        farm => farm.value === selectedFarm.id
      )
      setValue('farm_id', farmProps)
    }
  }, [activeFarmsDroplist, isEditing, selectedFarm, setValue])

  useEffect(() => {
    if (!isEditing) {
      const suggestionCostCenterId = suggestion?.cost_center?.id
      const suggestionClassificationId = suggestion?.classification?.id

      if (suggestionClassificationId)
        setValue('classification_id', suggestionClassificationId)

      if (suggestionCostCenterId)
        setValue('cost_center_id', suggestionCostCenterId)
    }
  }, [isEditing, suggestion, setValue])

  useEffect(() => {
    if (isEditing && editItem) {
      const {
        bulletin_number,
        classification_id,
        cost_center_id,
        date,
        duration,
        executor_id,
        executor_name,
        final_mileage,
        initial_mileage,
        local_id,
        local_name,
        machinery_id,
        machinery_name,
        observation,
        service_id,
        service_name,
        service_type,
      } = editItem

      validateOptionalFields('bulletin_number', bulletin_number)
      validateOptionalFields('cost_center_id', cost_center_id)
      validateOptionalFields('classification_id', classification_id)

      setServiceType(service_type)
      setServiceAndLocal({
        serviceId: String(service_id),
        locationId: String(local_id),
      })

      setValue('executor_id', {
        label: executor_name,
        value: executor_id,
      })
      setValue('service_id', {
        label: service_name,
        value: service_id,
      })
      setValue('location_id', {
        label: local_name,
        value: local_id,
      })
      setValue('cost_center_id', cost_center_id)
      setValue('classification_id', classification_id)

      setValue('service_type', service_type)
      setValue('duration', parseMinutesToHours(duration))
      setDurationTime(parseMinutesToHours(duration))
      setValue('observation', observation)

      handleExecutionDate(dateTimeFormat(date, true))

      const editItemLocation = locations?.find(
        location => location.id === local_id
      )

      if (editItemLocation) {
        setValue('farm_id', {
          label: editItemLocation.farm_name,
          value: editItemLocation.farm_id,
        })

        setSelectedFarm({
          id: String(editItemLocation.farm_id),
          name: editItemLocation.farm_name,
        })
      }

      if (machinery_name) {
        setOperatedMachine(true)
        setValue('is_machinery_used', operatedMachineOptions[0].value)
        setValue('machinery_id', {
          label: machinery_name,
          value: machinery_id,
        })
        setValue('initial_mileage', initial_mileage)
        setValue('final_mileage', final_mileage)
        setOdometer({
          kmInitial: Number(initial_mileage),
          kmFinal: Number(final_mileage),
        })
      } else {
        setValue('is_machinery_used', operatedMachineOptions[1].value)
      }
    }
  }, [
    editItem,
    handleExecutionDate,
    isEditing,
    locations,
    setError,
    setValue,
    operatedMachineOptions,
    setSelectedFarm,
    validateOptionalFields,
  ])

  useEffect(() => {
    setModifiedFields(formState.dirtyFields)
  }, [formState])

  useEffect(() => {
    setValue(
      'odometer',
      odometer.kmInitial < odometer.kmFinal
        ? odometer.kmFinal - odometer.kmInitial
        : 0
    )
  }, [odometer, setValue])

  return (
    <CustomDrawer
      title={`${isEditing ? 'Editar' : 'Lançar'} Boletim de Serviço`}
      isOpen={isDrawerOpen}
      onClose={toggleDrawer}
      scrollableDrawer
      className={styles.drawer}
    >
      <BulletinForm>
        {isEditing && (
          <ul className={styles.tabs}>
            {tabs.map((tab, index) => (
              <li key={index}>
                <Button
                  className={classNames(
                    styles.tabsItem,
                    activeTabIndex === index && styles.tabsItemActive
                  )}
                  htmlType="button"
                  label={tab.label}
                  onClick={(): void => setActiveTabIndex(index)}
                />
              </li>
            ))}
          </ul>
        )}

        {showForm && (
          <>
            <DrawerRow singleItem>
              <BulletinForm.Select
                label="Fazenda"
                name="farm_id"
                options={activeFarmsDroplist}
                onChange={handleFarmChange}
              />
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <BulletinForm.InputText
                  label="Número do boletim"
                  placeholder="12345"
                  htmlType="number"
                  name="bulletin_number"
                  max={9999999999}
                  onChange={handleBulletinChange}
                  value={bulletinNumber}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow className={styles.selectDate}>
              <BulletinForm.RadioButton
                buttonMode
                title="Data de execução"
                name="date"
                options={bulletinDate}
                defaultChecked={bulletinDate[selectedDateIndex]}
                onChange={(): void => setDateHasChangedManually(false)}
              />
              <div>
                <Button
                  type={ButtonType.primary}
                  htmlType="button"
                  label="Outra"
                  size={ButtonSize.small}
                  className={styles.buttonDate}
                  onClick={handleOtherDate}
                />
                <input
                  type="date"
                  ref={inputDateRef}
                  max={new Date().toISOString().split('T')[0]}
                  name="execution_date_manual"
                  className={styles.manualDate}
                  onChange={(e): void => {
                    setDateHasChangedManually(true)
                    handleExecutionDate(e.target.value)
                  }}
                />
              </div>
            </DrawerRow>

            <DrawerRow singleItem>
              <BulletinForm.RadioButton
                buttonMode
                title="Tipo de serviço"
                name="service_type"
                options={serviceTypeRadio}
                defaultChecked={serviceTypeRadio.find(
                  e => e.value === serviceType
                )}
                onChange={(e): void => {
                  setServiceType(e.currentTarget.value as ServiceType)
                }}
              />
            </DrawerRow>

            <DrawerRow singleItem>
              <BulletinForm.Select
                label="Executor"
                name="executor_id"
                placeholder="Selecione o executor"
                options={executorGroupedOptions}
                formatOptionLabel={(option): void =>
                  formattedOptionWithFarm(option, 'executer')
                }
                formatGroupLabel={(group): JSX.Element => (
                  <GroupLabel group={group} />
                )}
                noOptionsMessage="Nenhum executor encontrado"
                customMenuListElement={
                  <div className={styles.changeService}>
                    <hr className={styles.divider} />
                    <Typography
                      text={`Mostrar executores de ${
                        executersFilter
                          ? 'outras fazendas'
                          : `Fazenda ${selectedFarm?.name}`
                      }`}
                      variant={TypographyVariant.link}
                      className={styles.changeServiceText}
                      onClick={(): void =>
                        setExecutersFilter(
                          executersFilter ? undefined : selectedFarm?.id
                        )
                      }
                    />
                  </div>
                }
                spaceBetween
              />
            </DrawerRow>

            <DrawerRow singleItem>
              <BulletinForm.Select
                label="Serviço"
                name="service_id"
                placeholder="Selecione o serviço"
                options={serviceGroupedOptions}
                formatOptionLabel={(option): JSX.Element => (
                  <ServiceOption option={option} />
                )}
                formatGroupLabel={(group): JSX.Element => (
                  <GroupLabel group={group} />
                )}
                noOptionsMessage="Nenhum serviço encontrado"
                customMenuListElement={
                  <div className={styles.changeService}>
                    <hr className={styles.divider} />
                    <Typography
                      text={`Mostrar serviços de  ${
                        serviceType == ServiceType.generalService
                          ? ServiceType.mechanization
                          : ServiceType.generalService
                      }`}
                      variant={TypographyVariant.link}
                      className={styles.changeServiceText}
                      onClick={(): void => {
                        const newServiceType =
                          serviceType == ServiceType.generalService
                            ? ServiceType.mechanization
                            : ServiceType.generalService
                        setServiceType(newServiceType)
                        setValue('service_id', '')
                      }}
                    />
                  </div>
                }
                onChange={(e): void =>
                  setServiceAndLocal({
                    serviceId: (e as SelectItemProps).value,
                    locationId: serviceAndLocal?.locationId,
                  })
                }
              />
            </DrawerRow>

            <DrawerRow singleItem>
              <BulletinForm.Select
                label="Local"
                name="location_id"
                placeholder="Selecione um local da fazenda"
                options={locationsDroplist}
                onChange={(e): void =>
                  setServiceAndLocal({
                    serviceId: serviceAndLocal?.serviceId,
                    locationId: (e as SelectItemProps).value,
                  })
                }
                noOptionsMessage="Nenhum local encontrado"
              />
            </DrawerRow>

            <div className={styles.subForm}>
              {hasServiceAndLocal && costCentersList && classificationsList ? (
                <>
                  <section className={styles.treeView}>
                    <div className={styles.treeViewLabel}>
                      <Typography
                        text="Centro de custo"
                        variant={TypographyVariant.label}
                      />
                      {costCenterSelected && (
                        <Typography
                          text={` - ${costCenterSelected}`}
                          variant={TypographyVariant.label}
                        />
                      )}
                    </div>
                    <TreeView
                      containerClassName={styles.treeViewContainer}
                      control={control}
                      errorMessage={formState?.errors.cost_center_id?.message}
                      fieldName="cost_center_id"
                      items={costCentersList}
                      onSelect={(id: number): void =>
                        setValue('cost_center_id', id)
                      }
                      testId='costCenterTree'
                      value={costCenterId}
                    />
                  </section>

                  <section className={styles.treeView}>
                    <div className={styles.treeViewLabel}>
                      <Typography
                        text="Classificação"
                        variant={TypographyVariant.label}
                      />
                      {classificationSelected && (
                        <Typography
                          text={` - ${classificationSelected}`}
                          variant={TypographyVariant.label}
                        />
                      )}
                    </div>
                    <TreeView
                      containerClassName={styles.treeViewContainer}
                      control={control}
                      errorMessage={
                        formState?.errors.classification_id?.message
                      }
                      fieldName="classification_id"
                      items={classificationsList}
                      onSelect={(id: number): void =>
                        setValue('classification_id', id)
                      }
                      testId='classificationTree'
                      value={classificationId}
                    />
                  </section>

                  {!isEditing && hasSuggestion && (
                    <Typography
                      text="Sugestão baseada na última vez que este serviço e local foram utilizados."
                      variant={TypographyVariant.p}
                      className={styles.suggestText}
                    />
                  )}
                </>
              ) : (
                <Typography
                  text="Selecione Serviço e Local para obter sugestão."
                  variant={TypographyVariant.p}
                  className={styles.suggestText}
                />
              )}
            </div>

            <DrawerRow singleItem>
              <div className={styles.durationSuggestions}>
                <DurationSuggestions
                  durationTime={durationTime}
                  onChange={(e): void => {
                    setDurationTime(e.currentTarget.value)
                    setValue('duration', e.currentTarget.value, {
                      shouldDirty: true,
                    })
                  }}
                  suggestions={['01:00', '02:00', '03:00', '08:00']}
                />
                <BulletinForm.InputTime
                  emptyLabelSpacing
                  labeledInputClassName={styles.durationInput}
                  name="duration"
                  value={durationTime}
                  onChange={(_, value): void => setDurationTime(value)}
                />
              </div>
            </DrawerRow>

            <DrawerRow singleItem>
              <BulletinForm.RadioButton
                className={styles.radioButton}
                buttonMode
                title="Operou maquinário"
                name="is_machinery_used"
                onChange={isMachineryUsedChange}
                options={operatedMachineOptions}
              />
            </DrawerRow>

            {operatedMachine && (
              <div className={styles.subForm}>
                <DrawerRow singleItem>
                  <BulletinForm.Select
                    label="Maquinário"
                    name="machinery_id"
                    placeholder="Selecione o maquinário"
                    onChange={handleMachineryChange}
                    options={machineriesGroupedOptions}
                    formatGroupLabel={(group): JSX.Element => (
                      <GroupLabel group={group} />
                    )}
                    formatOptionLabel={(option): void =>
                      formattedOptionWithFarm(option, 'machinery')
                    }
                    customMenuListElement={
                      <div className={styles.changeService}>
                        <hr className={styles.divider} />
                        <Typography
                          text={`Mostrar maquinários de ${
                            machineryFilter
                              ? 'outras fazendas'
                              : `Fazenda ${selectedFarm?.name}`
                          }`}
                          variant={TypographyVariant.link}
                          className={styles.changeServiceText}
                          onClick={(): void =>
                            setMachineryFilter(
                              machineryFilter ? undefined : selectedFarm?.id
                            )
                          }
                        />
                      </div>
                    }
                    noOptionsMessage="Nenhum maquinário encontrado"
                  />
                </DrawerRow>

                <DrawerRow className={styles.odometer}>
                  <DrawerCol>
                    <BulletinForm.InputText
                      label="Km Inicial"
                      placeholder="0"
                      name="initial_mileage"
                      htmlType="number"
                      onChange={(e): void =>
                        handleMileageChange(e, 'initial_mileage')
                      }
                      appendHelperText="km"
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <BulletinForm.InputText
                      label="Km Final"
                      placeholder="0"
                      name="final_mileage"
                      htmlType="number"
                      onChange={(e): void =>
                        handleMileageChange(e, 'final_mileage')
                      }
                      appendHelperText="km"
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <BulletinForm.InputText
                      className={styles.odometerField}
                      label="Odômetro"
                      name="odometer"
                      htmlType="number"
                      appendHelperText="km"
                      readOnly
                    />
                  </DrawerCol>
                </DrawerRow>
              </div>
            )}

            <DrawerRow singleItem>
              <BulletinForm.Textarea
                className={styles.additionalComment}
                label="Comentário (opcional)"
                placeholder="Insira descrição adicional"
                name="observation"
                maxLength={300}
              />
            </DrawerRow>

            <DrawerButtonContainer className={styles.buttonContainer}>
              <Button
                testId="cancelButton"
                type={ButtonType.ghost}
                label="Cancelar"
                size={ButtonSize.medium}
                htmlType="button"
                className={styles.cancelButton}
                onClick={toggleDrawer}
              />

              <div className={styles.buttonContainerSave}>
                {!isEditing && (
                  <Button
                    type={ButtonType.outline}
                    label="Lançar e continuar"
                    size={ButtonSize.medium}
                    className={styles.saveThrowButton}
                    htmlType="button"
                    onClick={handleSubmit(handleBulletinAndContinue)}
                  />
                )}
                <Button
                  testId="submitButton"
                  type={ButtonType.primary}
                  label={`${isEditing ? 'Editar' : 'Lançar'} boletim`}
                  size={ButtonSize.medium}
                  className={styles.saveButtons}
                />
              </div>
            </DrawerButtonContainer>
          </>
        )}

        {activeTabIndex === historyTabIndex && editItem && (
          <ServiceOrderHistory
            serviceOrderId={editItem?.id}
            classifications={classifications}
            costCenters={costCenters}
            executers={executers}
            farms={farms}
            locations={locations}
            machineries={machineries}
            services={services}
          />
        )}
      </BulletinForm>
    </CustomDrawer>
  )
}

export { OSDrawer }
