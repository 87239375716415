import React from 'react'

import { Typography, TypographyVariant } from 'components/atoms'

import { useSector } from 'app/core/hooks'
import {
  AnimalHistoryProps,
  AnimalHistoryTypes,
  AnimalSex,
} from 'app/core/types/animal'
import { AnimalEvaluations } from 'app/core/types/evaluation'
import { ShutdownTypeKeys } from 'app/core/types/shutdown'
import { SectorProps } from 'app/core/types/system'
import { dateTimeFormat } from 'app/core/utils'

import { Col, InfoCard, Row } from '../infocard'
import styles from './styles.module.scss'

const dateFields = [
  'shutdown_date',
  'insemination_date',
  'diagnostic_date',
  'date',
  'birth_date',
  'birth_forecast',
]

const getEntryTitle = (history: AnimalHistoryProps): string => {
  const mapping = {
    [AnimalHistoryTypes.move]: 'Movimentação',
    [AnimalHistoryTypes.animal_entity]: 'Animal',
    [AnimalHistoryTypes.first_handling]: 'Primeiro Manejo',
    [AnimalHistoryTypes.fourth_month_handling]: 'Manejo do 4º mês',
    [AnimalHistoryTypes.weaning_handling]: 'Manejo de Desmama',
    [AnimalHistoryTypes.fattening_handling]: 'Manejo de Engorda',
    [AnimalHistoryTypes.sanitary_handling]: 'Manejo Sanitário',
    [AnimalHistoryTypes.breeding_station]: 'Estação de Monta',
    [AnimalHistoryTypes.breeding_group]: 'Grupo de repasse',
    [AnimalHistoryTypes.iatf_schedule]: 'Programação IATF',
    [AnimalHistoryTypes.animal_shutdown]: 'Baixa',
    [AnimalHistoryTypes.breeding_diagnostic]: 'Diagnóstico de Gestação',
    [AnimalHistoryTypes.insemination]: 'Inseminação',
    [AnimalHistoryTypes.give_birth]: 'Parto',
    [AnimalHistoryTypes.be_born]: 'Cria',
    [AnimalHistoryTypes.delete_birth]: 'Exclusão de parto',
    [AnimalHistoryTypes.delete_breeding_diagnostic]: 'Diagnóstico de Gestação',
    [AnimalHistoryTypes.animal_evaluation]: 'Avaliação',
    [AnimalHistoryTypes.mating_recommendation]: 'Recomendação de Acasalamento',
  }
  const typeName = mapping[history.type] ?? ''
  if (history.is_removal) {
    return `Remoção de ${typeName}`
  }
  if (history.is_update) {
    return `Alteração de ${typeName}`
  }
  if (typeName == mapping[AnimalHistoryTypes.delete_birth]) {
    return typeName
  }
  return `Cadastro de ${typeName}`
}

const getEntryField = (field: string, isOldValue: boolean): string => {
  const mapping: Record<string, string> = {
    cowhide_color: 'Pelagem',
    date: 'Data',
    birth_date: 'Data do parto',
    electronic_eartag: 'Nº do brinco eletrônico',
    stock_number: 'Nº de plantel',
    reason: 'Motivo de Baixa',
    product: 'Produto utilizado',
    weight: 'Peso',
    current_weight: 'Peso',
    quantity: 'Quantidade',
    shutdown_date: 'Data da baixa',
    name: 'Nome',
    sex: 'Sexo',
    category: 'Categoria',
    calf_name: 'Nome da cria',
    insemination_date: 'Data de inseminação',
    type: 'Tipo',
    diagnostic_date: 'Data do diagnóstico',
    is_active: 'Ativo',
    sector_id: 'Setor',
    description: 'Descrição',
    calf_birth_type: 'Tipo de parto',
    calf_birth_number: 'Numero de nascimento',
    calf_sex: 'Sexo da cria',
    GMD: 'GMD',
    series: 'Série',
    imported_paternal_great_grandfather: 'Bisavô paterno',
    imported_paternal_grandfather: 'Avô paterno',
    imported_father: 'Pai',
    imported_maternal_great_grandfather: 'Bisavô materno',
    imported_maternal_grandfather: 'Avô materno',
    imported_mother: 'Mãe',
    gestation_days: 'Dias de gestação',
    birth_forecast: 'Previsão de parto',
    status: 'Diagnóstico',
    score: 'Nota',
    evaluation: 'Avaliação',
    average_daily_gain: 'GMD',
    male_name: 'Nome do macho recomendado',
  }

  if (field === 'is_active' && !isOldValue) {
    return 'Reversão de Baixa'
  }

  const fieldName = mapping[field] ?? ''
  if (!isOldValue) {
    return fieldName
  }
  return `${fieldName} Anterior`
}

const getEntryValue = (
  value: string | number | null,
  field: string,
  sectors?: SectorProps[]
): string | number => {
  if (value === null) {
    return '-'
  }

  const weightFields = ['weight', 'current_weight', 'GMD', 'average_daily_gain']

  if (weightFields.includes(field)) {
    return `${value} kg`
  }

  if (field == 'reason') {
    if (value === ShutdownTypeKeys.sale) {
      return 'Venda'
    }
    if (value === ShutdownTypeKeys.death) {
      return 'Morte'
    }
    return 'outro'
  }

  if (field == 'sex') {
    return AnimalSex[value as keyof typeof AnimalSex]
  }

  if (field == 'status') {
    if (value) {
      return 'Positivo'
    }

    return 'Negativo'
  }

  if (field == 'is_active') {
    return 'Sim'
  }

  if (dateFields.includes(field)) {
    return dateTimeFormat(value as string)
  }

  if (field === 'sector_id') {
    return sectors?.find(sector => sector.id === value)?.name || value
  }

  if (field == 'calf_sex') {
    return AnimalSex[value as keyof typeof AnimalSex]
  }

  if (field == 'score') {
    return AnimalEvaluations[value as keyof typeof AnimalEvaluations]
  }

  return value
}

const Entry: React.FC<{ history: AnimalHistoryProps }> = ({ history }) => {
  const { sectors } = useSector()

  return (
    <div className={styles.entry}>
      <Row>
        <Typography
          text={getEntryTitle(history)}
          variant={TypographyVariant.h5}
          className={styles.entryName}
        />
        <Typography
          text={dateTimeFormat(history.date)}
          variant={TypographyVariant.caption3}
          className={styles.entryDate}
        />
      </Row>
      <Col>
        <Row>
          {!history.data.some(
            item =>
              item.field &&
              dateFields.some(dateField => item.field.includes(dateField))
          ) && (
            <Col>
              <Typography
                text="Data"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={dateTimeFormat(history.date)}
                variant={TypographyVariant.p}
              />
            </Col>
          )}
          {history.data.map((item, index) => (
            <Col key={`history-column-${index}`}>
              <Typography
                text={getEntryField(item.field, item.is_old_value)}
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={getEntryValue(item.value, item.field, sectors)}
                variant={TypographyVariant.p}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </div>
  )
}

type AnimalHistoryCardProps = {
  history: AnimalHistoryProps[]
  fieldsToDisplay?: AnimalHistoryTypes[]
}

const AnimalHistory: React.FC<AnimalHistoryCardProps> = ({
  fieldsToDisplay,
  history,
}) => {
  const historySorted = history
    .filter(item => !fieldsToDisplay || fieldsToDisplay.includes(item.type))
    .sort((a, b) => b.date.localeCompare(a.date))

  return (
    <div className={styles.history}>
      <InfoCard className={styles.entriesInfoCard} title="Histórico do Animal">
        {historySorted.map((handling, i) => (
          <Entry history={handling} key={i} />
        ))}
      </InfoCard>
    </div>
  )
}

export { AnimalHistory }
