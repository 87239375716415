import React, { Dispatch, SetStateAction } from 'react'

import { BreedingExpandedInfo } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  IExpandCardProps,
  withLoading,
} from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { BreedingStationReadResponseData } from 'app/core/types/breeding'

import styles from './styles.module.scss'

type BreedingStationHistoryProps = {
  stations?: BreedingStationReadResponseData
  farms?: SelectItemProps[]
  sectors?: SelectItemProps[]
  setPage: Dispatch<SetStateAction<number>>
  page: number
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  isLoading: boolean
  onExportClick: () => void
  isLoadingExport: boolean
  handleDeleteModalClose: () => void
  handleDeleteModalConfirm: () => Promise<void>
  deleteBreedingStationId: number | undefined
  setDeleteBreedingStationId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  onDetailsClick: (stationId: number) => void
  onCreateClick: () => void
  activeFilters: number
  onResetFilters: () => void
  form: FormType
}

const BreedingStationHistoryTemplate: React.FC<BreedingStationHistoryProps> = ({
  stations,
  farms,
  sectors,
  setPage,
  page,
  isDrawerOpen,
  handleToggleDrawer,
  isLoading,
  handleDeleteModalClose,
  handleDeleteModalConfirm,
  deleteBreedingStationId,
  setDeleteBreedingStationId,
  onExportClick,
  isLoadingExport,
  onDetailsClick,
  onCreateClick,
  activeFilters,
  onResetFilters,
  form: Form,
}) => {
  const editButtonProps = (
    stationId: number
  ): Pick<IExpandCardProps, 'buttonLabel' | 'buttonIcon' | 'buttonAction'> => ({
    buttonLabel: 'Editar',
    buttonIcon: IconNames.pencil,
    buttonAction: (): void => {
      onDetailsClick(stationId)
    },
  })

  return (
    <>
      <BoxedLayout
        title="Estações de Monta"
        setCurrentPage={setPage}
        currentPage={page}
        totalPages={stations?.pages}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
        {...(isAdmin() && {
          headerLargeButtonIcon: <Icon name={IconNames['add-circle']} />,
          headerLargeButtonLabel: 'Criar',
          headerLargeButtonAction: onCreateClick,
        })}
      >
        {withLoading(
          <>
            <DataCounter
              counter={stations?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.stationsContainer}>
              {stations?.items.map((station, i) => (
                <ExpandCard
                  title={station.name}
                  expandable={!!station.breeding_groups?.length}
                  initDate={station.init_date}
                  endDate={station.final_date}
                  animalsMale={station.males}
                  animalsFemale={station.females}
                  isActive={station.is_active}
                  navigation={(): void => onDetailsClick(station.id)}
                  {...editButtonProps(station.id)}
                  key={`station-${i}`}
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      setDeleteBreedingStationId(station.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                >
                  <div className={styles.stationExpandedContainer}>
                    {station.breeding_groups?.length && (
                      <BreedingExpandedInfo
                        title="Grupos de Repasse"
                        multipleValues={station.breeding_groups}
                      />
                    )}

                    {station.hormonal_protocol && (
                      <BreedingExpandedInfo
                        title="Protocolo hormonal"
                        singleValue={station.hormonal_protocol.name}
                      />
                    )}
                  </div>
                </ExpandCard>
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={onResetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            {farms && (
              <DrawerCol>
                <Form.Select label="Fazenda" name="farm_id" options={farms} />
              </DrawerCol>
            )}
            {sectors && (
              <DrawerCol>
                <Form.Select label="Setor" name="sector_id" options={sectors} />
              </DrawerCol>
            )}
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <Form.InputText name="init_date" htmlType="date" />
                <Form.InputText name="final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>
          <div className={styles.breedingStationNameRow}>
            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Nome da Estação de Monta"
                  name="breeding_station_name"
                  placeholder="Pesquisar nome da estação de monta"
                />
              </DrawerCol>
            </DrawerRow>
          </div>
          <DrawerButtonContainer>
            <Button
              label="Aplicar"
              type={ButtonType.primary}
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!deleteBreedingStationId}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Estação de Monta`}
        modalText={`Você tem certeza que deseja excluir esta estação de monta?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export default BreedingStationHistoryTemplate
