import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { ISelectButtonItem, SearchAnimal, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { useFarm, useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { EvaluationReadResponseData } from 'app/core/types/evaluation'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  start_date: yup.string(),
  end_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { start_date } = this.parent
        return isEndDateBeforeStartDate(start_date, value)
      }
    ),
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
})

type BirthHistoryTemplateProps = {
  evaluations?: EvaluationReadResponseData
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isDrawerOpen: boolean
  onExportClick: () => void
  handleToggleDrawer: () => void
  onSubmitFilter: IFormProps['onSubmit']
  isLoading: boolean
  isLoadingExport: boolean
  headerSelectButtonItems: ISelectButtonItem[]
}

const EvaluationHistoryTemplate: React.FC<BirthHistoryTemplateProps> = ({
  evaluations,
  currentPage,
  setCurrentPage,
  isDrawerOpen,
  handleToggleDrawer,
  onExportClick,
  onSubmitFilter,
  isLoading,
  isLoadingExport,
}) => {
  const history = useHistory()
  const { sectorsDroplist } = useSector()
  const { farmsDroplist } = useFarm()

  const {
    Form: FilterForm,
    formState,
    reset,
  } = useForm({ onSubmit: onSubmitFilter, validationSchema })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
  }

  return (
    <>
      <BoxedLayout
        title="Avaliação de animais"
        currentPage={currentPage}
        totalPages={evaluations?.pages}
        setCurrentPage={setCurrentPage}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
      >
        <SearchAnimal navigation={NavigateList.evaluationDetails} />

        {withLoading(
          <>
            <DataCounter
              counter={evaluations?.total}
              counterLabel={'Registros'}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            {evaluations?.items.map(evaluation => (
              <ExpandCard
                key={evaluation.id}
                evaluation={evaluation}
                expandable={false}
                navigation={(): void =>
                  history.push(
                    `${NavigateList.evaluationDetails}${evaluation.animal_id}`
                  )
                }
              />
            ))}
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <FilterForm>
          <DrawerRow>
            <DrawerCol column>
              <label htmlFor="start_date">Período da Avaliação</label>
              <div>
                <FilterForm.InputText name="start_date" htmlType="date" />

                <FilterForm.InputText name="end_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <FilterForm.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <FilterForm.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <FilterForm.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {farmsDroplist && (
              <DrawerCol>
                <FilterForm.Select
                  label="Fazenda"
                  name="farm_id"
                  options={farmsDroplist}
                />
              </DrawerCol>
            )}
            {sectorsDroplist && (
              <DrawerCol>
                <FilterForm.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <FilterForm.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <FilterForm.InputText name="init_date" htmlType="date" />
                <FilterForm.InputText name="final_date" htmlType="date" />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </FilterForm>
      </CustomDrawer>
    </>
  )
}

export { EvaluationHistoryTemplate }
