import { ServiceType } from './system'

export type ServiceOrderProps = {
  farm_id: number
  bulletin_number: number
  date: string
  executor_id: number
  service_id: number
  service_type: ServiceType
  location_id: number
  classification_id: number
  cost_center_id: number
  duration: number
  is_machinery_used?: boolean
  machinery_id?: number
  initial_mileage?: number
  final_mileage?: number
  odometer?: number
  observation?: string
}

export type ServiceOrderListProps = {
  init_date?: string
  final_date?: string
  service_type?: string | ServiceType | null
  contains_name?: string
}

export type ServiceOrderInfoProps = {
  id: number
  date: string
  bulletin_number: number
  service_name: string
  service_id: number
  service_type: ServiceType
  duration: number
  local_name: string
  local_id: number
  executor_name: string
  executor_id: number
  classification_name: string
  classification_id: number
  classification_code: string
  cost_center_name: string
  cost_center_id: number
  machinery_name?: string
  machinery_id?: number
  initial_mileage?: number
  final_mileage?: number
  odometer?: number
  observation?: string
  is_draft: boolean
  new?: boolean
}

export type ServiceOrderDraftInfoProps = Omit<
  ServiceOrderInfoProps,
  'bulletin_number' | 'classification_id' | 'cost_center_id'
> & {
  bulletin_number: number | null
  classification_id: number | null
  cost_center_id: number | null
}

export type ServiceOrderListResponse = {
  items?: ServiceOrderInfoProps[]
  total?: number
  page?: number
  pages?: number
}

export type ListServiceOrderProps = {
  init_date?: string
  final_date?: string
  service_type?: string | ServiceType | null
  contains_name?: string
  page?: number
  farm_id?: number | null
  location_id?: number
}

export type ServiceOrderExportProps = {
  id: number
  machineryName?: string
  date: Date
  bulletinNumber: number
  duration: number
  odometer: number
  executorRegistrationNumber: number
  executorName: string
  classificationCode: string
  classificationName: string
  costCenterCode?: number
  costCenterName?: string
  farmName?: string
}

export enum ServiceOrderDateTypes {
  day = 'Dia',
  week = 'Semana',
  month = 'Mês',
}
export type ServiceOrderFormattedDate = {
  currentDate: Date
  finalDate: Date
  dateType: ServiceOrderDateTypes
  formatted: string
}

export enum ServiceOrderDateFormatTypes {
  currentDay = 'E',
  day = "d LLLL', 'E",
  week = 'd LLLL',
  month = 'LLLL',
}

export type ServiceOrderUpdateData = {
  serviceOrderId: number
  payload: Partial<ServiceOrderProps>
}

export enum ServiceOrderCategory {
  cost = 'Custo',
  investment = 'Investimento',
}

export enum ServiceOrderHistoryDataTypes {
  bulletin_number = 'Nº do Boletim',
  date = 'Data',
  executor_id = 'Executor',
  farm_id = 'Fazenda',
  location_id = 'Local',
  service_id = 'Serviço',
  is_machinery_used = 'Usou maquinário',
  classification_id = 'Classificação',
  cost_center_id = 'Centro de Custo',
  initial_mileage = 'Km inicial',
  final_mileage = 'Km final',
  odometer = 'Odômetro',
  machinery_id = 'Maquinário',
  observation = ' Comentário',
  duration = 'Duração',
  created_by = 'Criado por',
}

export type ServiceOrderHistoryDataProps = {
  field: keyof typeof ServiceOrderHistoryDataTypes
  value: number | string
  is_old_value: boolean
}

export type ServiceOrderHistoryProps = {
  id: number
  is_update: boolean
  updated_by: number
  date: string
  is_removal: boolean
  data: ServiceOrderHistoryDataProps[]
}
