import React from 'react'

import classNames from 'classnames'

import { Status as InputStatus } from 'components/enums/status'
import { IInputProps } from 'components/types/input'

import styles from './styles.module.scss'

export interface IInputTextProps extends IInputProps {
  autoFocus?: boolean
  htmlType?: string
  status?: InputStatus
  placeholder?: string
  defaultValue?: string
  width?: string | number
  min?: string | number
  max?: string | number
  step?: string
  value?: string
  prependHelperText?: string
  appendHelperText?: string
  containerClassName?: string
  onFocus?: () => void
  readOnly?: boolean
}

const InputText = React.forwardRef<HTMLInputElement, IInputTextProps>(
  (
    {
      autoFocus = false,
      name,
      onChange,
      onBlur,
      htmlType = 'text',
      disabled = false,
      className,
      status = 'default',
      id,
      placeholder,
      defaultValue,
      width,
      min = 0,
      max,
      step,
      value,
      prependHelperText,
      appendHelperText,
      containerClassName,
      onFocus,
      readOnly,
      ...restProps
    },
    ref
  ): JSX.Element => {
    return (
      <div
        className={classNames(
          styles.inputContainer,
          { [styles.disabled]: disabled },
          readOnly && styles.readOnly,
          styles[status],
          containerClassName
        )}
      >
        {prependHelperText && (
          <div className={styles.prependHelpText}>{prependHelperText}</div>
        )}
        <input
          disabled={disabled}
          autoFocus={autoFocus}
          id={id ?? name}
          className={classNames(styles.input, className)}
          style={{ minWidth: width || 'auto' }}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={htmlType}
          step={step}
          min={min}
          max={max}
          name={name}
          readOnly={readOnly}
          {...restProps}
          ref={ref}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
        />
        {appendHelperText && (
          <div className={styles.appendHelpText}>{appendHelperText}</div>
        )}
      </div>
    )
  }
)

export { InputText }
