import * as Animal from '../pages/animals'
import * as Birth from '../pages/births'
import * as Breeding from '../pages/breeding'
import * as CollectiveMovements from '../pages/collective-movements'
import { Dashboard } from '../pages/dashboard'
import * as ElectronicEartag from '../pages/electronic-eartag'
import * as Evaluation from '../pages/evaluation'
import * as Farm from '../pages/farm'
import * as Handling from '../pages/handlings'
import * as Hormonal from '../pages/hormonal'
import { Login } from '../pages/login'
import * as MatingRecommendation from '../pages/mating-recommendation'
import * as MeasuringStation from '../pages/measuring-station'
import { Parameters } from '../pages/parameters'
import * as Password from '../pages/password'
import { Precipitation } from '../pages/precipitation'
import * as Sector from '../pages/sector'
import { SelectService } from '../pages/select-service'
import { ServiceOrders } from '../pages/service-orders'
import { ServiceOrdersReview } from '../pages/service-orders/review'
import * as Settings from '../pages/settings'
import * as Shutdown from '../pages/shutdown'
import * as StockNumber from '../pages/stock-number'
import * as User from '../pages/users'
import { IAppRoute } from './types'

export interface IParamProps {
  animalId: string
  handlingId: string
  stationId: string
  groupId: string
  protocolId: string
  scheduleId: string
  diagnosticGroupId: string
  inseminationId: string
  birthId: string
  farmId: string
  sectorId: string
  measuringStationId: string
}

export type NavigateParams = (
  path: NavigateList | string,
  params?: Record<string, string | number | boolean | undefined>
) => void

export enum NavigateList {
  home = '/',
  forgotPassword = '/esqueceu-senha/',
  redefinePassword = '/redefinir-senha/',
  selectService = '/selecionar-servico',
  serviceOrders = '/ordens-de-servico',
  serviceOrdersReview = '/revisao-geral/',
  dashboard = '/dashboard/',
  userList = '/usuarios/',
  userCreate = '/usuarios/adicionar/',
  userUpdate = '/usuarios/atualizar/',
  animalList = '/animais/',
  animalCreate = '/animais/adicionar/',
  animalReview = '/animais/revisar/',
  animalFeedback = '/animais/feedback/',
  animalRecord = '/animal/',
  electronicEartagCreate = '/brinco-eletronico/',
  electronicEartagReview = '/brinco-eletronico/revisar/',
  electronicEartagFeedback = '/brinco-eletronico/feedback/',
  handlingHistory = '/manejos/',
  handlingCreate = '/manejos/adicionar/',
  handlingReview = '/manejos/revisar/',
  handlingDetails = '/manejo/',
  handlingFeedback = '/manejos/feedback/',
  breedingStationHistory = '/estacoes/',
  breedingStationCreate = '/estacoes/adicionar/',
  breedingStationDetails = '/estacao/',
  breedingGroupHistory = '/repasses/',
  breedingGroupCreate = '/repasses/adicionar/',
  breedingGroupDetails = '/repasse/',
  inseminationHistory = '/coberturas/',
  inseminationCreate = '/coberturas/adicionar/',
  inseminationDetails = '/cobertura/',
  inseminationReview = '/cobertura/revisar/',
  inseminationFeedback = '/cobertura/feedback/',
  birthHistory = '/partos/',
  birthUpdate = '/partos/atualizar/',
  birthCreate = '/partos/adicionar/',
  birthReview = '/partos/revisar',
  birthFeedback = '/partos/feedback/',
  diagnosticsCreate = '/diagnosticos/adicionar/',
  diagnosticsDetails = '/diagnostico/',
  diagnosticsHistory = '/diagnosticos/',
  diagnosticsReview = '/diagnosticos/revisar/',
  diagnosticsFeedback = '/diagnosticos/feedback/',
  hormonalProtocolHistory = '/protocolos/',
  hormonalProtocolCreate = '/protocolos/adicionar/',
  hormonalProtocolDetails = '/protocolo/',
  iatfScheduleHistory = '/programacoes/',
  iatfScheduleCreate = '/programacoes/adicionar/',
  iatfScheduleDetails = '/programacao/',
  matingRecommendationHistory = '/recomendacoes-acasalamento/',
  matingRecommendationDetails = '/recomendacao-acasalamento/',
  collectiveMovementsCreate = '/movimentacoes-coletivas/adicionar/',
  collectiveMovementsReview = '/movimentacoes-coletivas/revisar/',
  collectiveMovementsFeedback = '/movimentacoes-coletivas/feedback/',
  evaluationHistory = '/avaliacoes/',
  evaluationDetails = '/avaliacao/',
  shutdownCreate = '/baixa/adicionar/',
  shutdownReview = '/baixa/revisar/',
  shutdownFeedback = '/baixa/feedback/',
  settings = '/configuracoes/',
  farmList = '/fazendas/',
  farmCreate = '/fazendas/criar/',
  farmEdit = '/fazendas/editar/',
  sectorList = '/setores/',
  sectorCreate = '/setores/criar/',
  sectorEdit = '/setores/editar/',
  precipitation = '/precipitacoes/',
  parameters = '/parametros/',
  measuringStationList = '/estacoes-medicao/',
  measuringStationCreate = '/estacoes-medicao/criar/',
  measuringStationEdit = '/estacoes-medicao/editar/',
  stockNumberCreate = '/numero-plantel/',
  stockNumberReview = '/numero-plantel/revisar/',
  stockNumberFeedback = '/numero-plantel/feedback/',
}

export const navigateToAnimal = (animalId: number | undefined): void => {
  if (typeof animalId === 'undefined') {
    return
  }

  window.open(`/animal/${animalId}/`, '_blank')
}

const checkBulletinRoutes = (routes: IAppRoute[]): IAppRoute[] => {
  return routes.map(route => {
    return route
  })
}

const passwordRoutes = [
  {
    path: NavigateList.forgotPassword,
    exact: true,
    component: Password.ForgotPassword,
  },
  {
    path: NavigateList.redefinePassword,
    exact: true,
    component: Password.RedefinePassword,
  },
]

const userRoutes = [
  {
    path: NavigateList.userList,
    exact: true,
    component: User.UserList,
    isPrivate: true,
  },
  {
    path: NavigateList.userCreate,
    exact: true,
    component: User.UserCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.userUpdate,
    exact: true,
    component: User.UserUpdate,
    isPrivate: true,
  },
]

const handlingRoutes = [
  {
    path: NavigateList.handlingHistory,
    exact: true,
    component: Handling.HandlingHistory,
    isPrivate: true,
  },
  {
    path: `${NavigateList.handlingDetails}:handlingId`,
    exact: true,
    component: Handling.HandlingDetails,
    isPrivate: true,
  },
  {
    path: NavigateList.handlingReview,
    exact: true,
    component: Handling.HandlingReview,
    isPrivate: true,
  },
  {
    path: NavigateList.handlingFeedback,
    exact: true,
    component: Handling.HandlingFeedback,
    isPrivate: true,
  },
  {
    path: NavigateList.handlingCreate,
    exact: true,
    component: Handling.HandlingCreate,
    isPrivate: true,
  },
]

const animalRoutes = [
  {
    path: NavigateList.animalList,
    exact: true,
    component: Animal.AnimalList,
    isPrivate: true,
  },
  {
    path: `${NavigateList.animalRecord}:animalId`,
    exact: true,
    component: Animal.AnimalRecord,
    isPrivate: true,
  },
  {
    path: NavigateList.animalCreate,
    exact: true,
    component: Animal.AnimalCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.animalReview,
    exact: true,
    component: Animal.AnimalReview,
    isPrivate: true,
  },
  {
    path: NavigateList.animalFeedback,
    exact: true,
    component: Animal.AnimalFeedback,
    isPrivate: true,
  },
]

const electronicEartagRoutes = [
  {
    path: NavigateList.electronicEartagCreate,
    exact: true,
    component: ElectronicEartag.ElectronicEartagCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.electronicEartagReview,
    exact: true,
    component: ElectronicEartag.ElectronicEartagReview,
    isPrivate: true,
  },
  {
    path: NavigateList.electronicEartagFeedback,
    exact: true,
    component: ElectronicEartag.ElectronicEartagFeedback,
    isPrivate: true,
  },
]

const breedingRoutes = [
  {
    path: NavigateList.breedingStationHistory,
    exact: true,
    component: Breeding.BreedingStationHistory,
    isPrivate: true,
  },
  {
    path: `${NavigateList.breedingStationDetails}:stationId`,
    exact: true,
    component: Breeding.BreedingStationDetails,
    isPrivate: true,
  },
  {
    path: NavigateList.breedingStationCreate,
    exact: true,
    component: Breeding.BreedingStationCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.breedingGroupHistory,
    exact: true,
    component: Breeding.BreedingGroupHistory,
    isPrivate: true,
  },
  {
    path: NavigateList.breedingGroupCreate,
    exact: true,
    component: Breeding.BreedingGroupCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.breedingGroupDetails}:groupId`,
    exact: true,
    component: Breeding.BreedingGroupDetails,
    isPrivate: true,
  },
  {
    path: NavigateList.inseminationHistory,
    exact: true,
    component: Hormonal.InseminationHistory,
    isPrivate: true,
  },
  {
    path: NavigateList.inseminationCreate,
    exact: true,
    component: Hormonal.InseminationCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.inseminationDetails}:inseminationId`,
    exact: true,
    component: Hormonal.InseminationDetails,
    isPrivate: true,
  },
  {
    path: `${NavigateList.inseminationReview}:inseminationId`,
    exact: true,
    component: Hormonal.InseminationReview,
    isPrivate: true,
  },
  {
    path: `${NavigateList.inseminationFeedback}:inseminationId`,
    exact: true,
    component: Hormonal.InseminationFeedback,
    isPrivate: true,
  },
  {
    path: `${NavigateList.diagnosticsCreate}`,
    exact: true,
    component: Breeding.DiagnosticsCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.diagnosticsDetails}:diagnosticGroupId`,
    exact: true,
    component: Breeding.DiagnosticsDetails,
    isPrivate: true,
  },
  {
    path: NavigateList.diagnosticsHistory,
    exact: true,
    component: Breeding.DiagnosticsHistory,
    isPrivate: true,
  },
  {
    path: `${NavigateList.diagnosticsReview}:diagnosticGroupId`,
    exact: true,
    component: Breeding.DiagnosticsReview,
    isPrivate: true,
  },
  {
    path: `${NavigateList.diagnosticsFeedback}:diagnosticGroupId`,
    exact: true,
    component: Breeding.DiagnosticsFeedback,
    isPrivate: true,
  },
]

const birthsRoutes = [
  {
    path: NavigateList.birthHistory,
    exact: true,
    component: Birth.BirthHistory,
    isPrivate: true,
  },
  {
    path: NavigateList.birthCreate,
    exact: true,
    component: Birth.BirthCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.birthReview,
    exact: true,
    component: Birth.BirthReview,
    isPrivate: true,
  },
  {
    path: NavigateList.birthFeedback,
    exact: true,
    component: Birth.BirthFeedback,
    isPrivate: true,
  },
  {
    path: `${NavigateList.birthUpdate}:birthId`,
    exact: true,
    component: Birth.BirthUpdate,
    isPrivate: true,
  },
]

const hormonalRoutes = [
  {
    path: NavigateList.hormonalProtocolHistory,
    exact: true,
    component: Hormonal.ProtocolHistory,
    isPrivate: true,
  },
  {
    path: NavigateList.hormonalProtocolCreate,
    exact: true,
    component: Hormonal.ProtocolCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.hormonalProtocolDetails}:protocolId`,
    exact: true,
    component: Hormonal.ProtocolDetails,
    isPrivate: true,
  },
  {
    path: NavigateList.iatfScheduleHistory,
    exact: true,
    component: Hormonal.ScheduleHistory,
    isPrivate: true,
  },
  {
    path: NavigateList.iatfScheduleCreate,
    exact: true,
    component: Hormonal.ScheduleCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.iatfScheduleDetails}:scheduleId`,
    exact: true,
    component: Hormonal.ScheduleDetails,
    isPrivate: true,
  },
]

const matingRecommendationRoutes = [
  {
    path: NavigateList.matingRecommendationHistory,
    exact: true,
    component: MatingRecommendation.MatingRecommendationHistory,
    isPrivate: true,
  },
  {
    path: `${NavigateList.matingRecommendationDetails}:animalId`,
    exact: true,
    component: MatingRecommendation.MatingRecommendationDetails,
    isPrivate: true,
  },
]

const collectiveMovementsRoutes = [
  {
    path: NavigateList.collectiveMovementsCreate,
    exact: true,
    component: CollectiveMovements.CollectiveMovementsCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.collectiveMovementsReview,
    exact: true,
    component: CollectiveMovements.CollectiveMovementsReview,
    isPrivate: true,
  },
  {
    path: NavigateList.collectiveMovementsFeedback,
    exact: true,
    component: CollectiveMovements.CollectiveMovementsFeedback,
    isPrivate: true,
  },
]

const evaluationRoutes = [
  {
    path: NavigateList.evaluationHistory,
    exact: true,
    component: Evaluation.EvaluationHistory,
    isPrivate: true,
  },
  {
    path: `${NavigateList.evaluationDetails}:animalId`,
    exact: true,
    component: Evaluation.EvaluationDetails,
    isPrivate: true,
  },
]

const shutdownRoutes = [
  {
    path: NavigateList.shutdownCreate,
    exact: true,
    component: Shutdown.ShutdownCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.shutdownReview,
    exact: true,
    component: Shutdown.ShutdownReview,
    isPrivate: true,
  },
  {
    path: NavigateList.shutdownFeedback,
    exact: true,
    component: Shutdown.ShutdownFeedback,
    isPrivate: true,
  },
]

const settingsRoutes = [
  {
    path: NavigateList.settings,
    exact: true,
    component: Settings.Settings,
    isPrivate: true,
  },
]

const farmRoutes = [
  {
    path: NavigateList.farmList,
    exact: true,
    component: Farm.FarmList,
    isPrivate: true,
  },
  {
    path: NavigateList.farmCreate,
    exact: true,
    component: Farm.FarmCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.farmEdit}:farmId`,
    exact: true,
    component: Farm.FarmCreate,
    isPrivate: true,
  },
]

const sectorRoutes = [
  {
    path: NavigateList.sectorList,
    exact: true,
    component: Sector.SectorList,
    isPrivate: true,
  },
  {
    path: NavigateList.sectorCreate,
    exact: true,
    component: Sector.SectorCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.sectorEdit}:sectorId`,
    exact: true,
    component: Sector.SectorCreate,
    isPrivate: true,
  },
]

const precipitationRoutes = [
  {
    path: NavigateList.precipitation,
    exact: true,
    component: Precipitation,
    isPrivate: true,
  },
]

const measuringStationRoutes = [
  {
    path: NavigateList.measuringStationList,
    exact: true,
    component: MeasuringStation.MeasuringStationList,
    isPrivate: true,
  },
  {
    path: NavigateList.measuringStationCreate,
    exact: true,
    component: MeasuringStation.MeasuringStationCreate,
    isPrivate: true,
  },
  {
    path: `${NavigateList.measuringStationEdit}:measuringStationId`,
    exact: true,
    component: MeasuringStation.MeasuringStationCreate,
    isPrivate: true,
  },
]

const stockNumberRoutes = [
  {
    path: NavigateList.stockNumberCreate,
    exact: true,
    component: StockNumber.StockNumberCreate,
    isPrivate: true,
  },
  {
    path: NavigateList.stockNumberReview,
    exact: true,
    component: StockNumber.StockNumberReview,
    isPrivate: true,
  },
  {
    path: NavigateList.stockNumberFeedback,
    exact: true,
    component: StockNumber.StockNumberFeedback,
    isPrivate: true,
  },
]

const serviceOrdersRoutes = [
  {
    path: NavigateList.serviceOrders,
    exact: true,
    component: ServiceOrders,
    isPrivate: true,
  },
  {
    path: NavigateList.serviceOrdersReview,
    exact: true,
    component: ServiceOrdersReview,
    isPrivate: true,
  },
  {
    path: NavigateList.selectService,
    exact: true,
    component: SelectService,
    isPrivate: true,
  },
  {
    path: NavigateList.parameters,
    exact: true,
    component: Parameters,
    isPrivate: true,
  },
]

const checkedBulletinRoutes = checkBulletinRoutes(serviceOrdersRoutes)

export const coreRoutes: IAppRoute[] = [
  { path: NavigateList.home, exact: true, component: Login },
  ...passwordRoutes,
  {
    path: NavigateList.dashboard,
    exact: true,
    component: Dashboard,
    isPrivate: true,
  },
  ...userRoutes,
  ...animalRoutes,
  ...handlingRoutes,
  ...birthsRoutes,
  ...hormonalRoutes,
  ...breedingRoutes,
  ...matingRecommendationRoutes,
  ...electronicEartagRoutes,
  ...collectiveMovementsRoutes,
  ...evaluationRoutes,
  ...shutdownRoutes,
  ...settingsRoutes,
  ...farmRoutes,
  ...sectorRoutes,
  ...precipitationRoutes,
  ...measuringStationRoutes,
  ...stockNumberRoutes,
  ...checkedBulletinRoutes,
]
