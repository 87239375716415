import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import { SelectedFiles } from 'use-file-picker'

import { SelectItemProps } from 'components/atoms'

import {
  deleteLocation as deleteLocationService,
  getAllLocations,
  patchUpdateLocation,
  postCreateLocation,
  postImportLocation,
} from 'app/core/services/system/location'
import { LocationHook } from 'app/core/types/hooks'
import {
  LocationCreateRequestData,
  LocationFormStateProps,
  LocationProps,
  LocationUpdateRequestData,
} from 'app/core/types/system'
import {
  addToast,
  checkImportInconsistencies,
  getPlural,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useLocation = (farmId?: string): LocationHook => {
  const [locations, setLocations] = useState<LocationProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleLocationErrors = (
    locationName: string,
    locationFarmName: string,
    error: unknown
  ) => {
    let message = (error as Error).message

    if (axios.isAxiosError(error) && error.response?.data['message']) {
      message = error.response?.data['message']
      addToast({
        message: ` ${locationName} (${locationFarmName}): ${message}`,
      })
    } else {
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const locationsDroplist = locations.map(location => {
    return {
      label: location.name,
      value: `${location.id}`,
    }
  }) as SelectItemProps[]

  const readAllLocation = useCallback(
    async (farmId?: string): Promise<void> => {
      try {
        setIsLoading(true)
        const data = await getAllLocations(farmId)

        const sortedLocations = [...data].sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()

          return nameA.localeCompare(nameB)
        })

        setLocations(sortedLocations)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const createLocation = useCallback(
    async (data: LocationFormStateProps): Promise<void> => {
      const { location, farms } = data

      setIsLoading(true)

      const successfulLocations = [] as LocationProps[]

      for (const farm of farms) {
        const farmName = farm.label

        try {
          const request = {
            name: location,
            farm_id: Number(farm.value),
            farm_name: farmName,
          } as LocationCreateRequestData

          const data = await postCreateLocation(request)

          successfulLocations.push({
            ...data,
            new: true,
          })

          const oldLocations = locations.map(location => ({
            ...location,
            new: false,
          }))

          setLocations([...successfulLocations, ...oldLocations])
        } catch (error) {
          handleLocationErrors(location, farmName, error)
        } finally {
          setIsLoading(false)
        }
      }

      if (successfulLocations.length > 0) {
        addToast({
          message: `${getPlural(
            'Local',
            successfulLocations.length,
            true
          )} ${getPlural('criado', successfulLocations.length)} com sucesso.`,
          type: 'success',
        })
      }
    },
    [locations]
  )

  const updateLocation = useCallback(
    async (
      locationId: number,
      request: LocationUpdateRequestData
    ): Promise<void> => {
      try {
        const data = await patchUpdateLocation(locationId, request)

        setLocations(prevLocations =>
          prevLocations.map(location =>
            location.id === locationId
              ? { ...data, new: true }
              : { ...location, new: false }
          )
        )

        addToast({
          message: Messages.SYSTEM_LOCATION_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }
    },
    []
  )

  const deleteLocation = useCallback(
    async (locationId: number): Promise<void> => {
      try {
        setIsLoading(true)

        await deleteLocationService(locationId)

        setLocations(prevLocations =>
          prevLocations.filter(location => location.id !== locationId)
        )

        addToast({
          message: Messages.SYSTEM_LOCATION_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const importLocation = useCallback(
    async (file: SelectedFiles) => {
      try {
        setIsLoading(true)

        const data = await postImportLocation(file)

        checkImportInconsistencies(data)

        addToast({
          message: Messages.SYSTEM_LOCATION_IMPORT_SUCCESS,
          type: 'success',
        })

        readAllLocation()
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    [readAllLocation]
  )

  useEffect(() => {
    readAllLocation(farmId)
  }, [readAllLocation, farmId])

  return {
    locations,
    locationsDroplist,
    createLocation,
    updateLocation,
    deleteLocation,
    importLocation,
    isLoading,
  }
}

export { useLocation }
